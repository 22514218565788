import React, { useContext, useEffect, useState } from "react";

import { Box, Divider, Typography, IconButton, Menu, MenuItem } from "@mui/material";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditIcon from "@mui/icons-material/Edit";

import * as S from "../styles";
import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { DeleteModal } from "./Modal";

const Card = ({ item, timeline, reference, dragging, ...props }) => {
  const { handleCardModal, socket, handlePostModal, handleModifyTarget } =
    useContext(ManagerContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [src, setSrc] = useState(null);

  const handleMenu = (e) => {
    e?.stopPropagation();

    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleDeleteModal = (e) => {
    e.stopPropagation();
    handleMenu();
    setIsDeleteModalOpened(true);
    setTargetId(item.id);

    // if (!window.confirm(" 정말 삭제하시겠습니까? \n 삭제된 게시물은 복구할 수 없습니다.")) return;

    // console.log("delete post");
  };

  const handleModifyPost = (e) => {
    e.stopPropagation();

    let target = { currentTarget: { id: item.section } };
    handlePostModal(target);
    handleModifyTarget(item);

    setAnchorEl(null);
  };

  useEffect(() => {
    if (!item || !item.file) return;

    fetch(`https://board.withplus.live/image?id=${item.id}`).then((res) => {
      res.blob().then((blob) => {
        const url = URL.createObjectURL(blob);

        setSrc(url);
      });
    });
  }, []);

  return (
    <S.Card
      className={"prevent-select" + (props["sort-mode"] === "true" && " sort-mode")}
      ref={reference}
      {...props}
      onClick={handleCardModal}
      item-id={item.id}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          p: "2.5px",
        }}
        size="small"
        onClick={handleMenu}
      >
        <MoreVertIcon fontSize="10px" />
      </IconButton>

      <Menu disablePortal anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenu}>
        <MenuItem onClick={handleModifyPost} id={targetId}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <EditIcon />

            <Typography sx={{ fontWeight: "600" }}>게시물 수정</Typography>
          </Box>
        </MenuItem>

        <MenuItem onClick={handleDeleteModal}>
          <Box sx={{ display: "flex", gap: "10px", color: "#f00", alignItems: "center" }}>
            <DeleteForeverOutlinedIcon fontSize="small" />

            <Typography fontWeight={600}>게시물 삭제</Typography>
          </Box>
        </MenuItem>
      </Menu>

      {isDeleteModalOpened && (
        <DeleteModal
          open={isDeleteModalOpened}
          onClose={(e) => {
            e?.stopPropagation();
            setIsDeleteModalOpened(false);
            setTargetId(null);
          }}
          targetId={targetId}
        />
      )}

      <Typography className="card-title" sx={{ wordBreak: "break-all" }}>
        {item.title}
      </Typography>

      {item.file && (
        <Box className="card-image">
          <img
            src={src}
            style={{ maxHeight: timeline ? "350px" : "400px", objectFit: "contain" }}
          />

          <Typography noWrap>{item.file.name}</Typography>
        </Box>
      )}

      <Typography className="card-content">{item.explain}</Typography>

      <Divider />

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box className="card-footer">
          <FavoriteIcon sx={{ color: "#c33c3c" }} />

          <Typography>{item.likes?.length || 0}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "5px", alignItems: "center", pr: "5px" }}>
          <ChatBubbleOutlineIcon fontSize="large" sx={{ scale: "0.6", color: "#555" }} />
          <Typography>{item.comments?.length || 0}</Typography>
        </Box>
      </Box>
    </S.Card>
  );
};

export default Card;
