import { styled as MuiStyled } from "@mui/material/styles";
import {
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Drawer as MuiDrawer,
  Tooltip,
} from "@mui/material";

export const Body = MuiStyled(Box)({
  height: "1px",
  minHeight: "calc(var(--vh, 1vh) * 100)",
  backgroundColor: "#fff",
  padding: "5px 0",
  boxSizing: "border-box",
});

export const container = MuiStyled(Container)({
  backgroundColor: "#f5f5f5",
  height: "100%",
  padding: "10px",
  borderRadius: "10px",
  boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",

  position: "relative",
  display: "flex",
  flexDirection: "column",
});

export const AddButton = MuiStyled(IconButton)({
  position: "absolute",
  bottom: 10,
  right: 10,
  backgroundColor: "#c33c3c",
  color: "white",
  width: "65px",
  height: "65px",
  boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.4)",

  "&:hover": {
    backgroundColor: "#c33c3c",
  },

  "&:active": {
    transform: "translateY(2px)",
    boxShadow: "none",
  },

  "> svg": {
    fontSize: "40px",
  },

  "@media (max-width: 600px)": {
    width: "50px",
    height: "50px",

    "> svg": {
      fontSize: "30px",
    },
  },
});

export const Drawer = MuiStyled((props) => (
  <MuiDrawer
    PaperProps={{
      sx: {
        height: "auto",
        display: "flex",
        alignItems: "end",
        bgcolor: "#00000000",
        boxShadow: "none",
        p: "0 10px",
        mr: "50px",

        "@media (max-width: 600px)": {
          mr: "0",
          height: "calc(var(--vh, 1vh) * 90)",
        },
      },
    }}
    {...props}
  />
))({});

export const PostingBox = MuiStyled(Box)({
  backgroundColor: "#fff",
  height: "100%",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "10px 10px 0 0",
  position: "relative",
  paddingBottom: "20px",
  overflow: "auto",

  "> .posting-header": {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",

    "> button": {
      font: "600 18px Pretendard",
    },
  },

  "> .posting-body": {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});

export const ImageBox = MuiStyled(Box)({
  height: "200px",
  borderRadius: "10px",
  border: "3px dashed #eee",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",

  "> .image-input": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    gap: "10px",
    cursor: "pointer",
    "> svg": { fontSize: "40px" },
    "> p.title": { font: "700 22px Pretendard" },
    "> p.explain": { font: "600 18px Pretendard" },
    "> input": { display: "none" },
  },

  "> .image-preview": {
    width: "100%",
    height: "100%",
    position: "relative",

    "> img": {
      height: "100%",
      objectFit: "contain",
    },

    "> button": {
      position: "absolute",
      bottom: 5,
      right: 5,
      fontWeight: "600",
    },
  },
});

export const Logo = MuiStyled(Box)({
  // position: "absolute",
  // top: 5,
  // left: 5,
  paddingLeft: "5px",
  paddingTop: "5px",
  display: "flex",
  gap: "5px",
  alignItems: "center",
  opacity: "0.85",
  width: "300px",

  "> img": {
    width: "30px",
  },

  "> p": {
    font: "800 20px Pretendard",
    color: "#222",
    marginTop: "5px",

    "> span": {
      fontWeight: "700",
    },
  },
});

export const Card = MuiStyled(Box)({
  backgroundColor: "#fff",
  width: "100%",
  borderRadius: "10px",
  padding: "10px",
  boxShadow: "0px 3px 6px #00000028",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  boxSizing: "border-box",
  marginBottom: "10px",
  cursor: "pointer",
  position: "relative",

  "> .card-title": {
    fontWeight: "700",
    fontSize: "18px",
  },

  "> .card-image": {
    "> img": {
      width: "100%",
      objectFit: "cover",
      objectPosition: "top",
    },

    "> p": {
      fontSize: "13px",
      fontWeight: "600",
      marginTop: "-5px",
      backgroundColor: "#f5f5f5",
    },
  },

  "> .card-content": {
    fontWeight: "500",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },

  "> div > .card-footer": {
    display: "flex",
    gap: "5px",
    alignItems: "center",

    "> svg": {
      fontSize: "18px",
    },
  },
});
