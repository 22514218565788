import React, { useEffect, useState } from "react";

import { Typography, Avatar, TextField, InputAdornment, Tooltip, Grid } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

import { Program } from "components/Program";
import { GET_LIST_HEXAGRAM } from "utils/api/Hexagram";
import { useCookies } from "react-cookie";

const gameList = [
  "Live Polling",
  "Live Quiz",
  "Live Board",
  "Live Pick",
  "Live Deal",
  "Egogram",
  "Hexagram",
]; // * 게임 리스트

const NewContent = ({ user }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const now = new Date();

  const [name, setName] = useState(null);
  const [status, setStatus] = useState(null);
  const [plan, setPlan] = useState("");
  const [search, setSearch] = useState("");
  const [hexagramPoint, setHexagramPoint] = useState(0);

  useEffect(() => {
    GET_LIST_HEXAGRAM(cookies.auth).then((res) => {
      const {success, message} = res.data;
      if(success) {
        setHexagramPoint(res.data.hexagramPoint);
      }else{
        switch (message) {
          case "Login Expired":
            alert("로그인이 만료되었습니다.");
            navigate("/login", { replace: true });
            break;
          default:
            alert("알 수 없는 오류가 발생했습니다.");
            break;
        }
      }
    })
  })

  useEffect(() => {
    if (!user) return;

    setName(user.name);

    if (user.status === "Group") {
      setStatus("단체 계정 (관리자)");
    } else {
      setStatus("개인 계정");
    }

    switch (user.plan) {
      case "basic_monthly":
        if (user.group?.isAccepted) {
          setPlan("[단체] 베이식 구독 (월간 결제)");
        } else {
          setPlan("베이식 구독 (월간 결제)");
        }
        break;
      case "basic_yearly":
        if (user.group?.isAccepted) {
          setPlan("[단체] 베이식 구독 (연간 결제)");
        } else {
          setPlan("베이식 구독 (연간 결제)");
        }
        break;
      case "free":
        if (new Date(user.ExpiredAt) > now) setPlan("무료 평가판");
        else setPlan("Free");
        break;
      case "basic":
        if (user.planType === "period") {
          setPlan("베이직 (기간 선택)");
        } else {
          setPlan("베이직 (구독)");
        }
        break;
      default:
        break;
    }
  }, [user]);

  const gotoGame = (e) => {
    const gameURL = e.target.id;
    navigate(`/simpleGame/${gameURL}`);
  };

  return (
    // NewDashBoardPage
    user && (
      <div className="NewDashBoard">
        {/* 상단 프로필 */}
        <div className="profile-box">
          <div className="profile">
            <Avatar
              sx={{
                width: "6vw",
                height: "6vw",
                minWidth: "10px",
                minHeight: "10px",
                maxWidth: "100px",
                maxHeight: "100px",
              }}
            />
            <div style={{ float: "left", padding: "20px", textAlign: "left" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  color="#707070"
                  fontSize="1.5rem"
                  fontFamily="Noto Sans"
                  fontWeight="700"
                >
                  {name}
                </Typography>
                <Tooltip title="프로필" arrow placement="top">
                  <SettingsIcon
                    sx={{
                      color: "#707070",
                      marginLeft: "20px",
                      fontSize: "28px",
                      cursor: "pointer",
                      transition: "0.3s ease-in-out",
                      "&:hover": {
                        scale: "1.2",
                      },
                    }}
                    onClick={() => navigate("profile")}
                  />
                </Tooltip>
              </div>
              <Typography color="#878787" fontWeight={500}>
                {status}
              </Typography>
            </div>
          </div>
          {/* 프로필 오른쪽 정보 */}
          <div className="info">
            <div className="text-box">
              <div>
                <Typography sx={{ color: "#797979", fontFamily: "Pretendard", fontWeight: "bold" }}>
                  구독 플랜
                </Typography>
                <Typography
                  sx={{
                    fontSize: "30px",
                    color: "#989898",
                    fontFamily: "Pretendard",
                    fontWeight: "500",
                  }}
                >
                  {/* {user} */}
                  {plan}
                </Typography>
              </div>
              <div>
                <Typography sx={{ color: "#797979", fontFamily: "Pretendard", fontWeight: "bold" }}>
                  보유 토큰 갯수
                </Typography>
                <Typography
                  sx={{
                    fontSize: "30px",
                    color: "#989898",
                    fontFamily: "Pretendard",
                    fontWeight: "500",
                  }}
                >
                  {hexagramPoint}개
                </Typography>
              </div>
            </div>
          </div>
        </div>
        {/* 0703 지원 개발 */}
        <ProgramsContainer />
        {/* <div className="MyProgram-container">
          <div className="Programs-box">
            <div className="Box-name">도구형 컨텐츠</div>
            <div className="Contents-box">
              <Program name="Live Polling" />
              <Program name="Live Quiz" />
              <Program name="Live Board" />
            </div>
          </div>
          <div className="Programs-box">
            <div className="Box-name">게임형 컨텐츠</div>
            <div className="Contents-box">
              <Program name="Live Pick" />
              <Program name="Live Deal" />
            </div>
          </div>
          <div className="Programs-box">
            <div className="Box-name">진단형 컨텐츠</div>
            <div className="Contents-box">
              <Program name="Egogram" />
              <Program name="Egogram" />
            </div>
          </div>
          <div className="Games-box">
            <div className="Box-name">간단 게임</div>
            <div className="Contents-box">
              <div className="Game-name" id="SimpleGameRoultte" onClick={gotoGame}>
                룰렛 돌리기
              </div>
              <div className="Game-name" id="SimpleGameGhostLeg" onClick={gotoGame}>
                사다리 타기
              </div>
              <div className="Game-name" id="SimpleGameArrowSpinning" onClick={gotoGame}>
                화살표 돌리기
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  );
};

const ProgramsContainer = () => {
  const navigate = useNavigate();

  const gotoGame = (e) => {
    const gameURL = e.target.id;
    navigate(`/simpleGame/${gameURL}`);
  };

  const programs = [
    { type: "도구형 컨텐츠", programs: ["Live Polling", "Live Quiz", "Live Board"] },
    { type: "게임형 컨텐츠", programs: ["Live Pick", "Live Deal"] },
    { type: "진단형 컨텐츠", programs: ["Egogram", "Hexagram"] },
    {
      type: "간단 게임",
      programs: [
        { name: "룰렛 돌리기", id: "SimpleGameRoultte" },
        { name: "사다리 타기", id: "SimpleGameGhostLeg" },
        { name: "화살표 돌리기", id: "SimpleGameArrowSpinning" },
      ],
    },
  ];

  return (
    <div className="MyProgram-container">
      {programs.map((program) => {
        if (program.type === "간단 게임") {
          return (
            <div className="Games-box" key={program.type}>
              <div className="Box-name">{program.type}</div>
              <div className="Contents-box">
                {program.programs.map((item) => (
                  <div className="Game-name" id={item.id} onClick={gotoGame} key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          );
        }
        return (
          <div className="Programs-box" key={program.type}>
            <div className="Box-name">{program.type}</div>
            <div className="Contents-box">
              {program.programs.map((name) => (
                <Program name={name} key={name} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NewContent;
