import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// import { GuideModal } from "components/Modal";
import { Box, Typography, Button } from "@mui/material";

// import livePollingGuideIMG from "assets/images/livepolling_guide.png";
// import QuizManual from "assets/images/QuizManual.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//
// import { pollIdList, pollingIdList } from "./temp";

const Title = (props) => {
  const path = useLocation().pathname.split("/").slice(1);

  // const [open, setOpen] = useState(false);
  // const [target, setTarget] = useState(null);
  // const [index, setIndex] = useState(0);

  const onClickGuide = () => {
    switch (props.children.name) {
      case "Live Polling":
        window.open(
          "https://withplus-live.notion.site/Live-Polling-69e60ce10c6c497fb6413a043604b259",
          "_blank"
        );
        return;

      case "Live Quiz":
        window.open(
          "https://withplus-live.notion.site/Live-Quiz-d7632fc9964a4eb7afb024887706264b",
          "_blank"
        );
        return;

      case "Live Board":
        window.open(
          "https://withplus-live.notion.site/Live-Board-97890159fce64d6db18f7abb7e616a71",
          "_blank"
        );
        return;

      default:
        alert("준비중입니다.");
        return;
    }
  };

  const goBack = () => {
    if (props.children.name === "Live Quiz") {
      window.location.href = `/features/quiz`;
    } else if (props.children.name === "Live Board") {
      window.location.href = `/board/list`;
    } else if (props.children.name === "Live Polling") {
      window.location.href = `/polling/list`;
    } else {
      window.location.href = `/hexagram/list`;
    }
  };

  // const nextPage = () => {
  //   console.log(index);
  //   if (index + 1 === pollingIdList.length) {
  //     alert("마지막 페이지입니다.");
  //     return;
  //   }

  //   window.open(`https://www.withplus.live/features/poll/app/${pollIdList[index + 1]}`, "_blank");
  //   window.location.href = `/polling/manager/${pollingIdList[index + 1]}/1709199040988`;
  // };

  // useEffect(() => {
  //   if (props.children.name === "Live Quiz") {
  //     setTarget(QuizManual);
  //   } else {
  //     setTarget(livePollingGuideIMG);
  //   }

  //   // let id = path[2];

  //   // if (id) {
  //   //   setIndex(pollingIdList.indexOf(id));
  //   // } else {
  //   //   setIndex(-1);
  //   // }
  // }, []);

  return (
    <Box
      sx={{ my: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={{
            fontFamily: "Pretendard",
            fontSize: "35px",
            fontWeight: "bold",
            color: "#B43F3F",
            mr: "10px",
          }}
        >
          {props.children.name}
        </Typography>
        <Button
          variant="contained"
          sx={{ font: "500 16px Pretendard", ml: "10px" }}
          onClick={onClickGuide}
        >
          사용 방법
        </Button>
        {/* <Button
        variant="contained"
        sx={{ font: "16px NotoSansKR-Medium", ml: "10px" }}
        href="https://www.withplus.live/pickTurorial"
        target="_blank"
      >
        튜토리얼
      </Button> */}

        {/* <GuideModal open={open} handleModal={onClickGuide} target={target}></GuideModal> */}
      </Box>

      {/* 테스트 */}
      {/* <Button onClick={nextPage}>hello</Button> */}

      <Box
        sx={{
          display: path[2] === "app" || path[1] === "manager" ? "flex" : "none",
          gap: "5px",
          cursor: "pointer",
          alignItems: "center",
          "&:hover": { transform: "scale(1.1)", bgcolor: "#efefef" },
          color: "#c33c3c",
          transition: "0.3s ease-in-out",
        }}
        onClick={goBack}
      >
        <ArrowBackIcon sx={{ fontSize: "35px !important" }} />
        <Typography sx={{ font: "bold 32px Pretendard" }}>나가기</Typography>
      </Box>
    </Box>
  );
};

export default Title;
