import { useEffect, useState } from "react";
import S from "../style.module.css";

const NinthPage = ({ resultObjectArray, userData, secondTypeArray }) => {
  const [userType, setUserType] = useState("");
  const [userTypeSplit, setUserTypeSplit] = useState("");
  useEffect(() => {
    if (resultObjectArray[4]?.name) {
      const name = resultObjectArray[4]?.name.split("(")[0];
      setUserType(resultObjectArray[4]?.name);
      setUserTypeSplit(name);
    }
  }, [resultObjectArray]);
  return (
    <div className={S.Page}>
      <div className={S.PageHeader}>HEXA GRAM Personality Profile</div>
      <div className={S.PageTitle}>
        <div className={S.TitlePart} style={{ backgroundColor: "#B02418" }}>
          Part IⅤ
        </div>
        <p className={S.TitlePartName}>
          {userData.name} 님의 심리적 욕구와 스트레스 반응_
          <span style={{ color: "#B02418" }}>기본타입_{userType}</span>
        </p>
      </div>
      <div className={S.UserStressTitle}>
        <p>심리적 욕구 & 스트레스</p>
      </div>
      <div className={S.UserStressContent}>
        <table>
          <thead>
            <tr>
              <th colSpan="2">{userTypeSplit} 심리적 욕구</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{secondTypeArray[3]?.desire}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan="2">{userTypeSplit} 스트레스 행동</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{secondTypeArray[3]?.stress}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={S.StressManagementTitle}>
        <p>스트레스 관리 방법</p>
      </div>
      <div className={S.StressManagementContent}>
        <p>{secondTypeArray[3]?.stressManage}</p>
      </div>
      <div className={S.PageFooter}>
        <p>All right reserved by Withplus Inc. copyright</p>
      </div>
    </div>
  );
};

export default NinthPage;
