import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://board.withplus.live",
});

// * 보드 추가 API
export const addBoard = (auth, title, explain, callback) => {
  axios.post("/add", null, { params: { auth, title, explain } }).then((res) => {
    callback(res);
  });
};

// * 보드 리스트 API
export const getBoardList = (auth, callback) => {
  axios.get("/list", { params: { auth } }).then((res) => {
    callback(res);
  });
};

// * 보드 삭제 API
export const deleteBoard = (boardIdList, callback) => {
  axios.post("/delete", null, { params: { boardIdList } }).then((res) => {
    callback(res);
  });
};

// * 보드 복제 API
export const duplicateBoard = (auth, boardId, callback) => {
  axios.post("/duplicate", null, { params: { auth, boardId } }).then((res) => {
    callback(res);
  });
};

// * 이미지 업로드 API
export const uploadImage = async (file, fileId, callback) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(`/image?name=${fileId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  callback(response);
};

// * 보드 리셋 API
export const resetBoard = (boardId, callback) => {
  axios.post("/reset", null, { params: { boardId } }).then((res) => {
    callback(res);
  });
};
