// 0704 지원 핵사그램 index 페이지
import { Route, Routes } from "react-router-dom";


import Auth from "auth/auth";
import TapListPage from "./ListPage";


const Index = () => {
  return (
    <Routes>
      <Route path="/list" element={<Auth Component={TapListPage} isAuthRequired />}></Route>
    </Routes>
  );
};

export default Index;