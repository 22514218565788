import { useEffect, useRef, useState } from "react";
import S from "../style.module.css";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import leftArrow from "assets/images/leftArrow2.png"

const SecondPageMobile = ({ resultObjectArray, onNextClick, onPrevClick, userData, firstTypeArray}) => {
  const chartRef = useRef(null);
  const [userType, setUserType] = useState("");
  const [userTypeSplit, setUserTypeSplit] = useState("");

  useEffect(() => {
    if (resultObjectArray[5]?.name) {
      const name = resultObjectArray[5]?.name.split("(")[0];
      setUserType(resultObjectArray[5]?.name);
      setUserTypeSplit(name);
    }
  }, [resultObjectArray]);

  useEffect(() => {
    const ctx = document.getElementById("radarChart").getContext("2d");
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    const labels = resultObjectArray.map((item) => item.name);
    const data = resultObjectArray.map((item) => item.num);

    chartRef.current = new Chart(ctx, {
      type: "radar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "검사 결과",
            data: data,
            backgroundColor: "rgba(8,31,92,0.5)",
            borderColor: "rgba(8,31,92,1)",
            borderWidth: 1,
            pointBackgroundColor: "rgba(8,31,92,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(8,31,92,1)",
          },
        ],
      },
      options: {
        scales: {
          r: {
            min: 0,
            max: 60,
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          datalabels: {
            display: true,
            color: "#081f5c",
            align: 'end',
            offset: 5,
            anchor: 'end',
            font: {
              size: 18,
            },
            formatter: function (value) {
              return value;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [resultObjectArray]);

  return (
    <div className={S.Page}>
      <div className={S.Title}>
        <p>{userType}</p>
      </div>
      <div className={S.PageContents}>
        <div className={S.SecondPageChart}>
          <canvas id="radarChart"></canvas>
        </div>
        <p className={S.SecondPageUserType}><span style={{fontWeight:"bold"}}>{userData.name}</span> 님의 기본 타입인 <span style={{fontWeight:"bold"}}>{userTypeSplit}</span>은 {firstTypeArray[0]?.typeDescription} </p>
      </div>
      <div className={S.PageFooter}>
        <div className={S.PrevBtn} onClick={onPrevClick}>
        ← 뒤로가기
        </div>
        <div className={S.NextBtn} onClick={onNextClick}>다음</div>
      </div>
    </div>
  );
};

export default SecondPageMobile;