import React from "react";
import { Route, Routes } from "react-router-dom";

import Auth from "auth/auth";

import ListPage from "./views/ListPage";
import ManagerPage from "./views/ManagerPage";
import UserPage from "./views/UserPage";

import { FullScreenProvider } from "contexts/FullScreen";
import { ManagerProvider } from "./contexts/Manager";

// * 보드 라우터
const index = () => {
  return (
    <Routes>
      {/* * 보드 리스트 페이지 */}
      <Route path="/list" element={<Auth Component={ListPage} isAuthRequired />} />
      <Route path="/manager/:gameId" element={<Auth Component={ManagerPage} isAuthRequired />} />

      <Route path="/user/:gameId" element={<UserPage />} />
    </Routes>
  );
};

export default index;
