export const ActorTypeMobile = [
  {
    type: "행동형",
    typeDescription: `수완이 좋고 매력적이며 적응력이 뛰어납니다. 추진력과 승부근성이 행동형의 강점입니다. 이들은 매우 매력적입니다. 이들은 위협을 하거나 유혹을 하여 자신이 원하는 것을 취할 수 있습니다. 그리고 외부의 자극을 추구합니다. 이들은 판매업에서 주로 성공하며, 기금모금이나 새로운 프로젝트를 시작하는 일을 잘합니다. 카리스마가 있어서 정치에 진출 하기도 하고 다른 공공 영역에서 영향력을 발휘하기도 하며, 훌륭한 기업가가 되기도 합니다.`,
  },
  {
    typeWord: `“밀어 붙여서 안되는게 어딨어!”, “어떤 상황도 해결해야 합니다”`,
    keyWord: `실행, 도전, 변화, 설득력, 성과중심(단기성과)`,
    typeDescription: `추진력과 강한 승부근성이 있다.기회에 민감하고 강한 도전정신을 보인다.변화분위기를 창출하고 변화를 창출하기 위한 비범한 방법을 구사한다.나아가야 할 방향에 대해 구성원들을 이해시키는 의사소통능력이 뛰어나 구성원들이 전달 메시지를 이해하고 일체화 할 수 있게 만든다.다른 사람들이 함께 행동하게 만든다.`,
  },
  {
    pros: `매사에 직접적이다
.추진력이 뛰어나다 
.설득력이 높다
.좋은(빠른) 결과를 만들어내려고 애쓴다
.자신감이 넘친다 
.활력이 넘친다`,
    cons: `경쟁심이 강하다
.주도권을 쥐려고 한다
.남에게 이것 저것 요구한다
.다른 사람의 감정을 이해하려고 하지 않는다
.참을성이 없다 
.진전이 느리면 짜증을 낸다 
.소극적인 태도를 보이기도 한다`,
  },
  {
    desire: `빠른 결과(즉각적인 보상)을 만들어내고자 하는 욕구.상황을 통제하고 싶은 욕구.명확하고 분명한 상황을 알고 싶은 욕구.도전적인 일을 하고 싶은 욕구.존재감을 드러내고 싶은 욕구.`,
    stress: `상대를 부추겨서 자신이 생각한 대로 움직인다
.도움이 필요한 사람에게 자신의 일은 자신이 해야 한다는 태도를 취한다
.자신에게 유리하게 이야기와 상황을 만들고, 유리하게 해석 하려한다.
.규칙을 깨고 자신은 특별하다는 태도를 취한다
.일부러 갈등을 만든다
.자신에게 필요 없다고 생각되는 사람과의 관계를 끊는다`,
    stressManage: `도전적인 환경이나 일을 만든다
.즉각적이고 빠르게 성취할 수 있는 일을 한다 
.스스로에게 관심과 보상을 자주 준다
.몸으로 할 수 있는 다양한 취미 활동을 한다
.모임에서 리더의 역할을 맡는다`
  },
  {
    relationship: `자신이 주도권을 쥠
.사람들을 자신의 통제 하에 두고 싶어함`,
    communication: `듣는 것 보다 말하는 것을 선호
.직선적인 표현을 주로 사용
.거침없이 말함
.결론을 중심으로 말함
.설득력이 있어 다른 이들을 행동하게 함`,
    motivation: `높은 수준의 업무나 목표를 제시한다
.변화와 도전할 수 있는 환경을 제공한다
.활동적인 업무에 배치한다
.실행력과 추진력에 대한 인정과 칭찬을 한다
.눈에 보이는 결과나 성과를 알려준다
.성취에 대해 즉각적으로 보상을 한다`
  }
];

export const PersisterTypeMobile = [
  {
    type: "신념형",
    typeDescription: `성실하고 헌신적이며, 매사에 원칙을 준수합니다. 신중함과 인재육성이 이들의 강점입니다. 스스로 먼저 활동하는 것과 타인이 먼저 움직이는 것을 기다리는 빈도가 거의 비슷합니다.  신념형은 매번 세세하게 따지는 경향이 있습니다. 이들은 회계, 경영, 법률, 그리고 세심한 사고능력이 요구되는 영역에서 그 능력을 발휘합니다. 또한 이들은 거의 실수를 하지 않기 때문에 훌륭한 관리자이기도 합니다. 이들은 조정과 통제에 관심이 많습니다.`,
  },
  {
    typeWord: `“최선을 다하는 것은 당연하고 잘하는 것도 중요하다!”, “최고가 되어야 한다”, “이왕 하는 거면 제대로 해야지“`,
    keyWord: `가치, 의미, 신념, 본질, 책임감, 성장과 육성, 중장기 목표와 비전`,
    typeDescription: `탁월한 책임감과 성실함으로 성과를 내기 위해 팀원들을 이끈다.자신의 핵심 가치에 근거하여 꾸밈없이 자유롭게 리더십 행동을 한다.매우 높은 도덕적, 윤리적 행동기준을 가지고 있고 항상 올바른 일을 하려고 한다.공통 비전과 미션을 바탕으로 구성원들을 동기부여 함으로써 하나로 집결시키고 구성원들이 개별 관심사를 뛰어넘도록 한다.개인의 가치관, 신념, 개별적 업무에 대한 의미 부여 등 질적인 측면에 관심을 갖는다.성장을 위한 진정성 있는 사실 기반의 피드백을 제공한다.강력한 역할 모델로서 행동한다`,
  },
  {
    pros: `성실하다
.빈틈이 없다
.헌신적이다
.원칙을 준수한다
.후배육성 능력이 높다 
.매사에 끈기가 있다
.사명감이 투철하다
.평가능력이 좋다
.예의를 중시한다`,
    cons: `예측하지 못한 상황은 피하려 한다
.나의 의견을 타인에게 강요하는 경향이 있다
.자기 주장이 강하다
.자신의 의견과 다를 때, 객관적 사실을 신뢰하지 않는 경향이 있다
.과도한 자기 규제를 보인다
.긴장상태가 오래간다
.일이 잘 못될 것에 대한 불안감이 높은 편이다`,
  },
  {
    desire: `신념을 인정받고 싶은 욕구.헌신(과정)에 대한 인정을 받고 싶은 욕구.영향력을 주고 싶은 욕구.타인에게 존경받고 싶은 욕구.같은 가치를 지닌 동료를 얻고 싶은 욕구.`,
    stress: `트집 잡고 지적하는 모습을 보인다
.완곡하지만 상대를 불쾌하게 만드는 말투를 사용한다
.본인의 기준으로 부정적 판단을 내리며 지적한다
.스스로를 탓하며 우울감과 자괴감에 빠진다`,
    stressManage: `여행이나 취미를 통한 여유 시간
.문제의 해결책을 세울 때 안도감 느낌
.해결할 수 있는 것과 없는 것 구분
.스스로에게 주는 다양한 보상체계
.다양한 업무 툴(Tool) 사용으로 업무속도 향상`
  },
  {
    relationship: `감정이 아닌 사고를 통해 접근 선호함
.사적보다는 업무 중심의 관계 시 편안함
.서로에게 의미 있는 존재가 되길 원함
.본인도 타인도 완벽을 추구하기를 기대함
.함께 성장하는 관계를 선호함`,
    communication: `의미와 가치가 중요
.의견을 주고 받는 소통방식 선호
.한 번 신뢰한 사람은 배신하지 않음
.다수보다 소수와의 소통 선호`,
    motivation: `세심한 사고능력이 요구되는 업무에 배치한다
.업무를 맡길 때 일의 의미와 가치를 설명해준다
.명확하게 목표를 제시하고 함께 방향성을 합의하는 과정을 거친다
.중요한 업무의 마지막 점검을 맡긴다
.후배 직원의 멘토 역할을 맡긴다
.피드백 시 직접 답을 주기보다 성찰을 통해 스스로 수정할 수 있도록 도와준다
.완벽하고 꼼꼼한 업무 결과 뿐 아니라 성실한 노력에 대해서도 인정해 준다`
  }
];

export const DreamerTypeMobile = [
  {
    type: "상상형",
    typeDescription: `사려 깊고 침착하며 상상력이 풍부합니다. 통찰력과 미래지향성이 그들의 강점입니다. 이들은 혼자 있을 때 행복합니다. 이들은 매우 창의적인 사고력을 지니고 있으며 많은 예술적 영역에 종사합니다. 많은 인내를 요구하는 일과 자신이 상상한 것을 구현하는 데에서 탁월한 일꾼입니다. 이들은 친절하고, 함께 지내기 편한 지지적인 사람으로 타인의 생활공간을 존중합니다.`,
  },
  {
    typeWord: `“생각할 시간이 필요해”, “정리되면 말해줄게”, “순리대로 하자” `,
    keyWord: `창의, 방향제시, 다양하고 깊은 사고, 신중함, 인내심, 위임, 인정과 배려`,
    typeDescription: `실행에 옮기기 전까지 독자적으로 충분한 시간을 들여 다각도로 사고하고 결정에 신중하다.미래지향적이며 구성원들에게 큰 그림과 나아가야 할 방향을 제시한다.구성원을 믿고 맡기는 경우가 많다(권한 위임과 자율성).구성원에게 충분한 지원과 일할 환경을 만들어준다.기존에 했던 방식과 차별화된 업무 방식을 선호한다.`,
  },
  {
    pros: `영감, 직관력이 뛰어나다 
.상상력이 뛰어나다
.생각이 깊고 침착하다
.인내심이 강하다
.통찰력이 있다
.인정이 많다
.대인관계를 오래 유지한다`,
    cons: `갈등을 회피한다
.깊이 관려하지 않는다
.지나치게 자기에게 몰입하는 경향이 있다
.자주 미루는 버릇이 있다
.우유부단하다
.활기차지 못하다`,
  },
  {
    desire: `자기성찰을 통해 상황을 이해하고 싶은 욕구.방해받고 싶지 않고 심리적 여유와 평화롭고 싶은 욕구.배려와 존중의 욕구.독립적으로 책임감을 갖고 일하고 싶은 욕구.나만의 시,공간을 갖고 깊은 욕구.`,
    stress: `점점 말 수가 적어져 말을 하지 않게 된다
.지시를 받지 않으면 무엇을 해야할지 혼란스러워한다
.여러가지 과제를 동시에 해야만 할 때 크게 당황하며 우선순위를 정하는데 어려움을 겪는다
.사람들과 이야기하지 않아도 되는 상황을 만든다
.시작한 일을 중도 포기한다
.틀어 박혀서 사람들과 교류를 끊는다
`,
    stressManage: `혼자만의 시간을 충분히 갖는다
.혼자 있을 수 있는 공간을 만든다
.혼자 여행을 한다
.좋아하는 장르의 책을 읽고 공상한다
.자연과 함께 하는 시간을 늘린다
.휴대전화, SNS를 잠시 꺼둔다`
  },
  {
    relationship: `혼자 있을 때 편안하고 행복함
.타인이 먼저 다가와 주기를 원함
.관계 형성되면 타인을 존중하고 친절함`,
    communication: `.스스로 생각할 시간을 보장해 주길 원함
.혼자만의 공간 침범하는 느낌 조심
.메시지는 한번에 하나씩 전달`,
    motivation: `독립적으로 일 할 수 있는 시간과 공간을 제공한다
.과도한 개입을 지양하고, 지시 후 아웃풋을 재촉하지 않는다
.동시에 여러 업무를 주지 않는다
.업무 수행과 관련하여 명확하고 구체적인 지시를 해준다
.큰 그림과 새로운 변화가 필요한 미래와 관련한 프로젝트나 업무에 배치한다
.창의성과 상상력을 펼칠 수 있게 격려한다`
  }
];

export const EmoterTypeMobile = [
  {
    type: "감성형",
    typeDescription: `동정심이 풍부하고 섬세하며 따뜻합니다. 사교성과 공감능력이 강점입니다. 감성형은 타인의 시선을 끌려고합니다. 이들은 사람과 사람 사이의 관계를 중시하며, 인생을 파티의 연속으로 만들려 합니다. 그리고 문제해결을 위해서는 먼저 해결하려 하는 자세를 취하며, 타인에게도 먼저 다가가는 경향이 있습니다.`,
  },
  {
    typeWord: `“세심하고 친절하며”, “동정심이 있고 따뜻하다.”`,
    keyWord: `관계, 공감, 감정, 신뢰`,
    typeDescription: `조직에서 가장 중요한 자원은 사람이라고 생각한다.구성원과 인간적으로 교감하며 신뢰관계를 빠르게 형성한다 .갈등을 원만히 해결할 수 있으며 팀을 조직하고 팀워크를 이끌어내는 능력이 탁월하다.경청, 설득과 대화를 통해 업무를 추진하며 팀을 형성한다.권위적인 모습보다는 공감하고 지원하며 심리적 여유를 만든다.조직구성원들에게 진심으로 관심을 갖고 건설적인 피드백과 조언자 역할을 한다`,
  },
  {
    pros: `동정심이 많다
.섬세하다
.따뜻하다
.관계를 중시한다
.감수성이 풍부하다
.사교성이 좋다
.공감능력이 뛰어나다`,
    cons: `민감하게 반응할 수 있다
.감정적이다
.과민 반응 한다
.자기 감정에 매몰된다
.타인의 탓으로 돌린다`,
  },
  {
    desire: `관계속에서 안정을 찾으려는 욕구.개인적인 관심과 인정을 받으려는 욕구.오감만족을 추구하고자 하는 욕구.업무나 관계속에서 즐거운 환경을 만들고자 하는 욕구.`,
    stress: `상대에게 너무 맞추려는 나머지 우유부단하게 된다
.상대를 기쁘게 해주려고 무엇이든 받아들여 부담이 과하게 된다
.주의력이 떨어지고 무의식적으로 실수를 연발한다
.기가 죽고, 자기불신, 지기비하, 낮은 자존감을 느낀다
.자극물(마약, 음주, 극단적 매운 것 등)을 원하게 된다
.＂나는 누구에게도 받아들여지지 않는다" 와 같이 거절된 느낌을 갖는다`,
    stressManage: `개인적인 관심과 인정, 표현을 많이 해준다
.친한 사람과 여유있는 시간을 보낸다
.추억의 사진이나 물건을 보이는 곳에 장식한다
.마사지를 받거나 아로마 향을 피워서 릴렉스한다
.자신에게 잘 어울리는 치장을 한다`
  },
  {
    relationship: `개인적인 관심과 인정을 많이 함
.타인이 나를 어떻게 생각하는지가 중요함
.타인의 기분이나 분위기를 맞추려 노력함
.남을 배려하고 편안하게 함
.상대방의 의견을 따르려고 함`,
    communication: `친근하고 다정하게 이야기함
.감정적인 표현을 많이 함
.이야기에 경청하고, 상황에 맞는 리액션을 잘함
.개인적인 이야기에도 관심이 많으며, 감정을 읽으려고 애씀
.편안한 분위기에서 이야기 하는 것을 선호`,
    motivation: `개인적 관심을 통해 신뢰를 형성한다
.어떤 부분에 어려움을 가지고 있는지 진정성을 같고 경청한다
.과제를 마칠 때까지 끊임없는 관심과 조언을 한다
.노력하는 부분에 대해 긍정적 피드백을 한다
.개선이 필요한 부분은 구체적으로 피드백하고 체크한다
.적절한 시기에 의사결정을 도와준다
.다른 구성원과 협업할 수 있는 기회를 마련해준다`
  }
];

export const AnalystTypeMobile = [
  {
    type: "분석형",
    typeDescription: `논리적이고 조직적이며 책임감이 강합니다. 분석력과 계획성이 강점입니다. 이들은 혼자 있을 때 행복해합니다. 또한 사회적인 관계를 가지려고 할 때 일대일 또는 두세 명 정도의 타인과 관계를 맺는 것을 선호합니다. 이들은 문제해결을 할 때에는 능동적입니다. 분석형은 자신의 말에 대해 책임지는 사람입니다. 이들은 자신이 할 수 있다고 말한 것은 무슨 일이든 해내는 멋진 사람입니다. 또한 사회를 유지하는 기둥 같은 존재라고 말할 수 있습니다.`,
  },
  {
    typeWord: `“일 먼저 합시다”, “근거가 무엇인가요?”, “사실인가요?”`,
    keyWord: `목표, 효율성, 계획, 문제해결, 문제 중심, 합리적`,
    typeDescription: `현재 조직이 추구하는 목표를 달성하거나 문제를 해결하는데 중점을 둔다.목표 지향적이며, 계획을 세워 완성도 높게 일을 완료한다.기한내에 마무리하는 것을 중요하게 생각한다.사실과 근거를 기반으로 업무를 처리한다.조직의 구조화 방안, 시스템의 체계화, 효과적 보상체계 구축 등에 신경을 쓴다.구성원의 발달 단계를 파악하여 리더십 스타일을 달리하는 것이 가장 효율적이라고 생각하기 때문에 상황대응리더십을 가장 잘 수용하고, 적용한다`,
  },
  {
    pros: `논리적이다
.조직적이다
.책임감이 투철하다
.분석력이 뛰어나다
.계획성이 높다`,
    cons: `일 중독 현상을 보일 수 있다
.과잉 억제한다
.과도한 긴장감으로 현재를 즐기지 못한다
.비판적이다`,
  },
  {
    desire: `업무 성취 및 전문성에 대해 인정받고 싶어하는 욕구.효율적인 것을 추구하는 욕구.계획에 따라 움직이는 욕구.`,
    stress: `생각이 지나치게 많아져 과도하게 변명하거나 장황하게 설명한다
.대화 도중 인내심이 없어지며 큰 소리로 먼저 대답해 버린다
.다른 사람에게 업무를 맡기지 못하고 본인이 먼저 일을 해버린다
.지시나 명령을 통해 다른 사람을 통제하려고 한다
.과정이 틀어지는 것에 화를 내고 규칙, 책임 등에 꼼꼼하고 까다로워진다
.자신이 하려는 일을 타인이 먼저 지적하면 화를 낸다
.업무 성과가 없거나 계획적이지 못한 타인을 거절하게 된다
.협업 하느니 혼자 한다고 마음먹고 타인과의 관계를 정리한다`,
    stressManage: `통제 가능한 상황을 만든다
.스케줄을 짠다
.자격증이나 학위 등 성취를 인정해 줄 수 있는 과정을 등록한다
.계획된 업무 완수 시 스스로에게 보상을 준다
.일 거리를 다 놓아버리고 계획된 휴가를 떠난다`
  },
  {
    relationship: `혼자 일하는 것을 편안해 함
.다수의 사람들보다는 소수의 사람과 함께 하는 것을 선호함
.비 업무적인 관계를 힘들어함`,
    communication: `질문을 던지는 요청형 의사소통 선호
.지시보다 질문을 좋아함
.핵심만 이야기하는 간결한 방식 선호
.근거를 제시하는 방식 신뢰
.성과나 업무 능력에 대한 인정과 칭찬이 효과적`,
    motivation: `업무관리 및 계획을 수립할 수 있도록 중장기 계획을 공유하고 사전에 스케쥴을 공지한다
.마감시한을 명시한 과제를 제시한다
.업무계획을 수립할 수 있는 시간 제공을 제공한다
.성과달성에 도움이 되는 구체적인 자료를 제공해준다
.달성한 업무 성과나 노력에 대해 인정하고 칭찬한다 
.전문성 확보를 위한 학습이나 경험의 기회를 제공한다
.지시보다 요청형 의사소통방식을 사용한다`
  }
];

export const PlayerTypeMobile = [
  {
    type: "유희형",
    typeDescription: `창의성이 뛰어나고 자발적이며 쾌활합니다. 창의성과 주도성이 이들의 강점입니다. 유희형은 타인과의 관계에서는 매우 활동적입니다. 이들은 집단의 구성원인 것을 즐깁니다. 이들은 유희를 할 수 있으나, 타인의 통제에 대하여 강하게 저항합니다. 이들은 훌륭한 조사관, 평론가, 탐정이 될 수 있습니다. 어떤 상황에서 무엇인가 잘못되는 것이 나타나면 이들이 제일 먼저 그 사실을 지적합니다.`,
  },
  {
    typeWord: `“재미가 중요해“, ”즐거운 인생“, ”남다르고 싶어＂`,
    keyWord: `열정, 즐거움, 창의, 자발`,
    typeDescription: `에너지가 넘치고, 즐거움을 추구하며 구성원과 함께 한다.창의적이고 다양성을 중요시하는 환경을 구축한다.구성원들이 자발적이고 열정적으로 움직여 주길 기대하며 참여를 촉진한다.기존의 방식과 다른 접근을 하거나 신선하고 혁신적인 아이디어가 많다.변화하는 환경을 예측하고 준비하는 능력이 탁월하다`,
  },
  {
    pros: `쾌활하며 삶을 즐길 줄 안다
.호기심이 많고 창조적이다
.유머 감각이 뛰어나고 긍정적이다 
.새로운 아이디어를 잘 내놓으며 재치있다
.활기가 있고 에너지가 넘친다`,
    cons: `산만하고 집중을 잘 못하는 경향이 있다
.감정 기복이 심하다
.불평이나 불만을 토로한다
.자기 입장을 고집하고, 협조하기를 거부한다
.이유 없는 투쟁, 반발을 한다
.혼자만의 성찰의 시간을 갖지 못한다`,
  },
  {
    desire: `끊임없는 재미와 흥미를 추구하는 욕구.새롭고 남다른 것을 추구하는 욕구.구속 받지 않고 자유롭고 싶은 욕구.과정 속에서 즐거움을 추구하는 욕구.즐거운 회합을 갖고 싶다는 욕구.`,
    stress: `이것 저것 일을 벌려서 수습하기 힘들어진다
.하고 싶지 않은 일을 할 때는 노력하려고 해도 금방 싫증이 난다
.자신이 싫은 일은 남에게 떠맡긴다
.관심이 없는 말은 안 들리는 척 한다
.제대로 되지 않는 일을 타인 탓으로 돌린다
.상대를 일부러 화나게 하려고 불평을 한다
.주위로부터 비난 받으면 복수심을 품는다
.자포자기 하게 된다`,
    stressManage: `농담을 하며 동료와 웃고 떠든다
.좋아하는 동물과 장난치며 논다
.노래방에서 춤추고 논다
.자연에서 피크닉이나 하이킹을 한다
.TV, 게임, 만화를 즐긴다`
  },  
  {
    relationship: `하고싶을 때 하고싶은 일을 함
.조금 일하고 실컷 놈
.유머와 웃음이 넘치는 인생을 추구함
.여러 사람들과 만나고, 여러 환경을 접함`,
    communication: `농담을 주고 받을 수 있는 재미있는 대화 선호 
.감정을 솔직하게 표현할 수 있는 대화
.호기심을 자극할 수 있는 주제 선호`,
    motivation: `.즐거운 분위기에서 일할 수 있는 환경을 제공한다
.팀으로 활동할 수 있는 업무에 배정한다
.과제를 부여할 때 곧바로 지시하기보다 재미있고 편안한 관계를 맺은 후 스스로 수용할 수 있도록 유도한다
.창의적인 아이디어가 필요한 프로젝트에 주도적인 역할을 부여한다
.조직에 사기를 불어넣는 역할을 맡긴다
.너무 세세한 가이드라인 제시와 타이트한 마감시간으로 압박감을 주지 않는다`
  }
];
