import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Grid, IconButton, Menu, MenuItem, Select, Tooltip, Typography } from "@mui/material";

// * 보드 리스트 페이지 스타일
export const Body = MuiStyled(Box)({
  minHeight: "100vh",
  height: "0px",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  overflow: "auto",
});

// * 보드 리스트 페이지 메인 컨테이너
export const Main = MuiStyled(Box)({
  height: "100%",
  width: "100%",
  margin: "auto",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});

export const Board = MuiStyled(Box)({
  position: "relative",
  height: "100%",
  backgroundColor: "#f5f5f5",
  borderRadius: "10px",
  boxShadow: "0px 3px 6px #00000028",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

// * 툴팁
export const ToolTip = (props) => {
  return (
    <Tooltip
      title={props.title}
      placement={props.placement}
      arrow
      PopperProps={{ disablePortal: true }}
    >
      {props.children}
    </Tooltip>
  );
};

export const MainContainer = MuiStyled(Box)({
  height: "100%",
  overflow: "auto",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",

  "> .main-header": {
    position: "relative",
    "> .title": {
      fontSize: "40px",
      color: "#222",
      fontWeight: "700",
    },

    "> .sub-title": {
      fontSize: "20px",
      color: "#797979",
    },
  },
});

export const Logo = MuiStyled(Box)({
  // position: "absolute",
  // top: 5,
  // left: 5,
  paddingLeft: "5px",
  paddingTop: "5px",
  display: "flex",
  gap: "5px",
  alignItems: "center",
  opacity: "0.85",

  "> img": {
    width: "30px",
  },

  "> p": {
    font: "800 20px Pretendard",
    color: "#222",
    marginTop: "5px",

    "> span": {
      fontWeight: "700",
    },
  },
});

export const QRCodeContainer = MuiStyled(Box)({
  position: "absolute",
  backgroundColor: "#fff",
  padding: "0 10px",
  boxSizing: "border-box",
  borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
  width: "25%",
  height: "calc(100vh - 20px)",
  right: 0,
  top: 0,
  transition: "transform 0.75s",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  boxShadow: "0px 3px 6px #00000028",
  borderRadius: "10px 0 0 10px",
  zIndex: 9999,

  "> .qrcode": {
    width: "50%",
    cursor: "pointer",
    "&:hover": { transform: "scale(1.05)" },
  },

  "> .access-code > p": {
    fontWeight: "bold",
    fontFamily: "Pretendard",
    textAlign: "center",
  },
});

export const MenuContainer = MuiStyled(Box)({
  position: "absolute",
  top: 5,
  right: 5,
  backgroundColor: "#fff",
  borderRadius: "20px",
  padding: "5px",
  boxShadow: "0px 3px 6px #00000028",
  zIndex: 10000,
});

export const MenuButton = MuiStyled(IconButton)({
  "> svg": {
    color: "#000",
  },
});

export const Settings = MuiStyled(Box)({
  width: "500px",
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  borderRadius: "10px 0 0 10px",
  backgroundColor: "#f5f5f5",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  overflow: "auto",

  "> .close-button": {
    position: "absolute",
    top: 5,
    left: 5,
  },

  "> .title": {
    fontWeight: "700",
    fontSize: "22px",
    textAlign: "center",
  },
});

export const SettingTitle = MuiStyled(Typography)({
  fontWeight: "700",
  fontSize: "18px",
});

export const SettingBox = MuiStyled(Box)({
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "10px 20px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export const SettingItem = MuiStyled(Box)({
  "> .title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "> p": {
      fontWeight: "600",
    },
  },

  "> .input": {
    paddingLeft: "10px",
    boxSizing: "border-box",
  },

  "&.layout": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "> .title": {
      display: "flex",
      gap: "5px",
      alignItems: "center",

      "> svg": {
        color: "#aaa",
      },
    },
  },
});

export const SelectBox = (props) => {
  return (
    <Select
      MenuProps={{ disablePortal: true }}
      size="small"
      sx={{ bgcolor: "#f5f5f5" }}
      {...props}
    />
  );
};

export const SelectItem = MuiStyled(Box)({
  display: "flex",
  gap: "10px",

  "> p": {
    fontWeight: "600",
  },

  // "> svg": {
  //   color: "#777",
  // },
});

export const Wall = MuiStyled(Grid)({
  height: "100%",
  boxSizing: "border-box",
});

export const WallGridItem = MuiStyled(Box)({
  boxSizing: "border-box",
  padding: "10px",
});

export const Card = MuiStyled(Box)({
  position: "relative",
  backgroundColor: "#fff",
  width: "100%",
  borderRadius: "10px",
  padding: "10px",
  boxShadow: "0px 3px 6px #00000028",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  boxSizing: "border-box",
  marginBottom: "10px",
  cursor: "pointer",
  paddingTop: "20px",

  "&.sort-mode": {
    border: "2px solid #c33c3c",
  },

  "&:hover": {
    backgroundColor: "#eee",
  },

  "> .card-title": {
    fontWeight: "700",
    fontSize: "18px",
  },

  "> .card-image": {
    "> img": {
      width: "100%",
      objectFit: "cover",
      objectPosition: "top",
    },

    "> p": {
      fontSize: "13px",
      fontWeight: "600",
      marginTop: "-5px",
      backgroundColor: "#f5f5f5",
    },
  },

  "> .card-content": {
    fontWeight: "500",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },

  "> div > .card-footer": {
    display: "flex",
    gap: "5px",
    alignItems: "center",

    "> svg": {
      fontSize: "18px",
    },
  },
});

export const TimeLine = MuiStyled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: "50px",
  padding: "20px",
  boxSizing: "border-box",

  "> .section": {
    width: "100%",
    overflow: "auto",
    display: "flex",
    gap: "20px",
    flexShrink: 0,

    "> div": {
      flexShrink: 0,
    },
  },
});
