import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../style.css";
import "components/Layout.css";
import MobileAccessRestricted from "../MobileAccessRestricted";
import axios from "axios";
import Content from "./main_old";
import Subscribe from "./Subscribe";
import Profile from "./Profile";
import Members from "./Members";
import { socket } from "../../utils/socket";
import { CustomLoadingButton as Button } from "components/Button";
// import NewContent from "views/NewDashBoardPage/main";
import NewContent from "./main";
import Notice from "components/Notice";

// * 대시보드 페이지
const DashBoardPage = (props) => {
  const [cookies, , removeCookie] = useCookies();
  const navigate = useNavigate();
  const path = useLocation()
    .pathname.split("/")
    .filter((_, idx) => idx !== 0);

  // * user info object
  const [user, setUser] = useState({});
  const [group, setGroup] = useState(null);
  const [expended, setExpended] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState(true);

  const handleExpended = () => {
    setExpended(!expended);
  };

  const rejectGroupJoin = (e) => {
    setLoading(true);
    axios
      .get("/api/group/reject", { params: { groupId: group.groupId, userId: user._id } })
      .then((res) => {
        const { success } = res.data;

        if (success) {
          setTimeout(() => {
            alert("그룹 초대 거절이 완료되었습니다.");
            window.location.reload();
          }, 750);
        }
      });
  };

  const acceptGroupJoin = (e) => {
    setLoading(true);
    axios
      .get("/api/group/accept", { params: { groupId: group.groupId, userId: user._id } })
      .then((res) => {
        const { success } = res.data;

        if (success) {
          setTimeout(() => {
            alert(" 그룹 초대 수락이 완료되었습니다. \n 그룹 계정의 구독 모델로 변경됩니다.");
            window.location.reload();
          }, 750);
        }
      });
  };

  useEffect(() => {
    /** 회원 탈퇴 */
    socket.on("withdrawal", (data) => {
      if (data.success) {
        alert("회원탈퇴가 완료되었습니다. \n 이용해주셔서 감사합니다.");
        removeCookie("auth", { path: "/" });
        removeCookie("ACCESS_TOKEN", { path: "/" });
        navigate("/");
      } else {
        alert("탈퇴에 오류가 생겼습니다 문의해주세요");
      }
    });

    return () => {
      socket.off("withdrawal");
    };
  });

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted || (window.performance && window.performance.navigation.type == 2)) {
        socket.disconnect();
        socket.connect();
      }
    };

    axios.get("/api/user", { params: { auth: cookies.auth } }).then((res) => {
      setUser(res.data.user);
    });

    if (localStorage.getItem("alert")) {
      localStorage.removeItem("alert");
    }

    if (localStorage.getItem("quiz-update") === "true") {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, []);

  useEffect(() => {
    if (!user.name) return;

    if (user.group) {
      axios.get("/api/group", { params: { groupId: user.group.groupId } }).then((res) => {
        let { group } = res.data;

        setGroup({ ...user.group, name: group.name });
      });
    }
  }, [user]);

  // const withdrawal = () => {
  // console.log(cookies.ACCESS_TOKEN);
  // if (window.confirm("Withplus Live 계정을 삭제하시겠습니까?")) {
  //   socket.emit("withdrawal", { auth: cookies.auth, ACCESS_TOKEN: cookies.ACCESS_TOKEN });
  // }
  // socket.emit("raiseError");
  // };

  // 반응형 웹
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  return (
    <>
      {isPc && (
        <div className="DashBoardLayout">
          {/* <Notice /> */}
          {/* 사이드바 */}
          <div className="side-bar">
            <div className="side-button-container">
              <div
                className={!path[1] ? "side-button-on" : "side-button"}
                onClick={() => {
                  navigate("");
                }}
              >
                <p>대시보드</p>
              </div>
              <div
                className={path[1] === "profile" ? "side-button-on" : "side-button"}
                onClick={() => {
                  navigate("profile");
                }}
              >
                <p>프로필</p>
              </div>
              <div
                className={path[1] === "subscribe" ? "side-button-on" : "side-button"}
                onClick={() => {
                  navigate("subscribe");
                }}
              >
                <p>상품 플랜</p>
              </div>

              {user.status === "Group" && (
                <div
                  className={path[1] === "members" ? "side-button-on" : "side-button"}
                  onClick={() => {
                    navigate("members");
                  }}
                >
                  <p>멤버 관리</p>
                </div>
              )}
            </div>
          </div>
          {/* 각 페이지 */}
          <div className="main-content">
            <Routes>
              <Route path="" element={<NewContent user={user} />} />
              {/* <Route path="/new" element={<NewContent user={user} />} /> */}
              <Route path="/profile" element={<Profile user={user} setUser={setUser} />} />
              <Route path="subscribe/*" element={<Subscribe user={user} />} />
              <Route path="members" element={<Members user={user} />} />
            </Routes>
          </div>

          {/* {!alert && (
            <Box sx={{ position: "absolute", top: "90px", right: "10px" }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: "flex", gap: "5px", font: "700 18px Pretendard" }}>
                    <Badge color="primary" overlap="circular" badgeContent=" " variant="dot" />
                    신규 콘텐츠 라이브 보드 출시 알림
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box>
                    <Typography sx={{ font: "600 16px Pretendard", lineHeight: "1.5" }}>
                      설문형 라이브 보드 신규 콘텐츠가 출시되었습니다. <br />
                      보드 형식을 통해 참여자들의 다양한 의견과 반응을 수집해보세요!
                    </Typography>

                    <Box sx={{ display: "flex", gap: "10px", mt: "10px" }}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          font: "600 16px Pretendard",
                          bgcolor: "#aaa",
                          "&:hover": { bgcolor: "#999" },
                        }}
                        onClick={() => {
                          localStorage.setItem("quiz-update", "true");
                          setAlert(true);
                        }}
                      >
                        다시 보지 않기
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )} */}

          {group && !group.isAccepted && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: "90px",
                  right: "10px",
                }}
              >
                <Accordion
                  disableGutters
                  expanded={expended}
                  onChange={handleExpended}
                  sx={{
                    transform: "translateY(10px)",
                    animation: expended ? "" : "float 2s ease-in-out infinite",
                    "@keyframes float": {
                      "0%": { transform: "translateY(10px)" },
                      "10%": { transform: "translateY(0px)" },
                      "20%": { transform: "translateY(10px)" },
                      "30%": { transform: "translateY(5px)" },
                      "40%": { transform: "translateY(10px)" },
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Badge
                        color="primary"
                        overlap="circular"
                        badgeContent=" "
                        variant="dot"
                      ></Badge>
                      <Typography sx={{ font: "600 18px Pretendard", pl: "5px" }}>
                        <span style={{ font: "700 19px Pretendard" }}>'{group.name}'</span> 그룹
                        계정에서 그룹 초대가 왔습니다.
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                      <Typography
                        sx={{ font: "500 15px Pretendard", lineHeight: "1.5", pl: "10px" }}
                      >
                        그룹 초대에 수락하시면 그룹에 가입하게 되고, <br /> 그룹에서 사용하는 구독
                        플랜을 사용하실 수 있습니다.
                        <br />
                        그룹에 참여하시려면{" "}
                        <span style={{ font: "700 18px Pretendard" }}>'수락' </span>
                        버튼을, 그룹에 참여하지 않으시려면{" "}
                        <span style={{ font: "700 18px Pretendard" }}>'거절'</span> 버튼을 클릭해
                        주세요.
                      </Typography>

                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            font: "600 16px Pretendard",
                            bgcolor: "#aaa",
                            "&:hover": { bgcolor: "#999" },
                          }}
                          id="reject"
                          onClick={rejectGroupJoin}
                          loading={loading}
                        >
                          거절
                        </Button>

                        <Button
                          variant="contained"
                          fullWidth
                          sx={{ font: "600 16px Pretendard" }}
                          id="accept"
                          onClick={acceptGroupJoin}
                          loading={loading}
                        >
                          수락
                        </Button>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {loading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bgcolor: "#00000030",
                    width: "100%",
                    height: "100vh",
                    zIndex: 100,
                  }}
                />
              )}
            </>
          )}
        </div>
      )}
      {isMobile && <MobileAccessRestricted />}
    </>
  );
};

export default DashBoardPage;
