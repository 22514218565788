import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import liveLogo from "assets/images/wliveLogo.png";

import "./Layout.css";

export const MobileHeader = () => {
  const navigate = useNavigate();
  const allDepth = window.location.href.split("/");
  const [, , removeCookie] = useCookies();

  const [isActive, setIsActive] = useState(false);
  const [isContentsBoxOpened, setContentsBoxOpened] = useState(false);
  const [isEventBoxOpened, setEventBoxOpened] = useState(false);

  // * 페이지 이동
  const goTo = (e) => {
    menuToggle("goTo");
    navigate(`/${e.currentTarget.id}`);
  };
  /** 로그아웃 */
  const logout = (e) => {
    alert("로그아웃 되었습니다.");
    removeCookie("auth", { path: "/" });
    removeCookie("ACCESS_TOKEN", { path: "/" });
    navigate("/");
  };

  /** 메뉴 토글 기능 */
  const menuToggle = (e) => {
    if (e === "goTo") {
      setIsActive(false);
      return;
    }
    setIsActive(!isActive);
  };

  const handleContentsBox = () => {
    setContentsBoxOpened(!isContentsBoxOpened);
  };

  const handleEventBox = () => {
    setEventBoxOpened(!isEventBoxOpened);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: "100",
        height: "80px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "white",
        alignItems: "center",
        px: "10px",
        boxShadow: "0px 3px 6px #00000028",
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          id=""
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": { background: "#efefef" },
            p: "10px 5px",
            width: "auto",
          }}
          onClick={goTo}
        >
          <img src={liveLogo} alt="w-logo" style={{ width: "130px" }} />
        </Box>
      </Box>
      {/* 메뉴 아이콘 */}
      <MenuIcon
        onClick={menuToggle}
        fontSize="large"
        color="primary"
        sx={{ cursor: "pointer", "&:hover": { transform: "scale(1.1)" }, transition: "0.25s" }}
      />
      {/* is active === ture 인 경우에 메뉴 열리게 (모바일메뉴) */}
      <ul className={isActive ? "mobile-menu-active" : "mobile-menu"}>
        <ul className="contents">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={handleContentsBox}
          >
            콘텐츠 소개
            <IconButton
              onClick={handleContentsBox}
              sx={{
                transform: isContentsBoxOpened ? "rotate(180deg)" : "rotate(0deg)",
                transition: "0.5s",
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              maxHeight: isContentsBoxOpened ? "auto" : 0,
              overflow: "hidden",
            }}
          >
            <li id="content/poll" onClick={goTo}>
              라이브 폴링
            </li>
            <li id="content/quiz" onClick={goTo}>
              라이브 퀴즈
            </li>
            <li id="content/board" onClick={goTo}>
              라이브 보드
            </li>
            <li id="content/pick" onClick={goTo}>
              라이브 픽
            </li>
            <li id="content/deal" onClick={goTo}>
              라이브 딜
            </li>
            <li id="content/egogram" onClick={goTo}>
              라이브 에고그램
            </li>
          </Box>
        </ul>

        <li id="plan" onClick={goTo} style={{ cursor: "pointer" }}>
          구독 플랜
        </li>

        <ul className="contents">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={handleEventBox}
          >
            이벤트
            <IconButton
              onClick={handleEventBox}
              sx={{
                transform: isEventBoxOpened ? "rotate(180deg)" : "rotate(0deg)",
                transition: "0.5s",
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              maxHeight: isEventBoxOpened ? "auto" : 0,
              overflow: "hidden",
            }}
          >
            <a
              href={`${allDepth[0]}//${allDepth[2]}/pickTutorial`}
              target="_blank"
              onClick={menuToggle}
            >
              <li>라이브픽 튜토리얼</li>
            </a>
            <a
              href={`${allDepth[0]}//${allDepth[2]}/dealTutorial`}
              target="_blank"
              onClick={menuToggle}
            >
              <li>라이브딜 튜토리얼</li>
            </a>
            <a
              href={`${allDepth[0]}//${allDepth[2]}/stopDrug`}
              target="_blank"
              onClick={menuToggle}
            >
              <li>서울시 청소년 마약 예방 캠페인</li>
            </a>
            <a
              href={`${allDepth[0]}//${allDepth[2]}/gyeonggi31`}
              target="_blank"
              onClick={menuToggle}
            >
              <li>경기도 3.1 운동</li>
            </a>
          </Box>
        </ul>

        <a
          href="https://withplus-live.notion.site/withplus-live/88c5c9e287ae4b0ebfe4d87400cfee42"
          target="_blank"
        >
          <li className="contents">도움말</li>
        </a>
        {/* 코드버튼과 마이페이지 */}
        <li style={{ marginTop: "30px" }}>
          <button id="code" className="code-button" onClick={goTo}>
            입력코드
          </button>
          {/* {cookies.auth ? (
            <button id="dashboard" className="header-menu" onClick={goTo}>
              마이페이지
            </button>
          ) : null} */}
        </li>
        {/* 로그인, 로그아웃 */}
        {/* <li>
          <button
            id="login"
            style={{
              background: "none",
              border: "none",
              fontFamily: "Roboto-Bold",
              fontSize: "1.2rem",
              color: "#505050",
            }}
            onClick={(e) => {
              cookies.auth ? logout() : goTo(e);
              menuToggle();
            }}
          >
            {cookies.auth ? "로그아웃" : "로그인"}
          </button>
        </li> */}
      </ul>
    </Box>
  );
};
