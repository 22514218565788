import axios from "axios";

export const GET_LIST_LIVETAP = (auth) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/live-tap/list", { params: { auth } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const POST_ADD_LIVETAP = (auth, name, gameMode, teamCount) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/live-tap/add", { auth, name, gameMode, teamCount })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};