// * npm modules
import React, { useContext, useEffect, useState } from "react";

// * material UI components
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import UndoIcon from "@mui/icons-material/Undo";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

// * project modules
import { ManagerContext } from "../contexts/ManagerContext";

import LoadingVideo from "assets/videos/loading.mov";
import sparkIcon from "assets/images/Icon/sparkIcon.svg";
import folders from "assets/images/Icon/folder";

// * styles
import * as S from "./styles";
import { useParams } from "react-router-dom";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { Textfield } from "components/Textfield";

// * OpenEnded Component
export default () => {
  const { gameId } = useParams();
  const { playingData, currentSlideIndex, socket, managerPlan } = useContext(ManagerContext); // * 플레이데잉 데이터와 현재 슬라이드 인덱스를 가져옴

  const [isFreePlan, setIsFreePlan] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(null); // * 현재 슬라이드
  const [userData, setUserData] = useState([]); // * 유저 데이터
  const [isGroupingStarted, setIsGroupingStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const [groupMode, setGroupMode] = useState(false);
  const [targetGroup, setTargetGroup] = useState(null);
  const [groupLength, setGroupLength] = useState(0);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const groupingAnswers = async () => {
    setIsLoading(true);
    await sleep(750);

    setIsGroupingStarted(true);
    socket.emit(
      "groupingAnswers",
      { gameId, userData, question: currentSlide.question, currentSlideIndex },
      (res) => {
        if (res.success) {
          setGroupData(res.groupData);
          setIsGroupingStarted(false);
          setIsLoading(false);
          setGroupMode(true);
        } else {
          alert("그룹지어내기에 실패했습니다. 다시 시도해주세요.");
          window.location.reload();
          // setIsLoading(false);
          // setIsGroupingStarted(false);
        }
      }
    );
  };

  const unGroupMode = () => {
    setGroupMode(false);
    socket.emit("unGroupMode", { gameId });
  };

  // * 플레이데잉 데이터가 바뀌면 현재 슬라이드와 유저 데이터를 업데이트
  useEffect(() => {
    if (!playingData) return; // * 플레이데잉 데이터가 없으면 리턴

    const currentSlide = playingData[currentSlideIndex]; // * 현재 슬라이드

    setCurrentSlide(currentSlide); // * 현재 슬라이드 업데이트
    setUserData(currentSlide.userData); // * 유저 데이터 업데이트
    setGroupData(currentSlide.groupData);
    setGroupMode(currentSlide.groupMode);
  }, [playingData, currentSlideIndex]);

  useEffect(() => {
    if (!groupData) return;
    const undefinedGroup = groupData.find((group) => group.groupId === "undefined");

    if (undefinedGroup) {
      if (undefinedGroup.answers.length === 0) {
        setGroupLength(groupData.length - 1);
        return;
      }
    }

    setGroupLength(groupData.length);
  }, [groupData]);

  useEffect(() => {
    if (managerPlan === "free") {
      setIsFreePlan(true);
    } else {
      setIsFreePlan(false);
    }
  }, [managerPlan]);

  return (
    <S.OpenEndedBody>
      {/* 질문 */}
      <S.OpenEndedQuestion>{currentSlide?.question || "설문형"}</S.OpenEndedQuestion>

      {/* 설문형 컨텐츠 */}
      <S.OpenEndedContent>
        {userData.length > 10 && !groupMode && !isFreePlan && (
          <Box>
            <S.AiButton variant="contained" onClick={groupingAnswers} loading={isLoading}>
              <AutoFixHighIcon />

              <Typography>현재 답변들을 그룹지어서 묶기</Typography>
            </S.AiButton>
          </Box>
        )}

        {groupMode && (
          <Box>
            <S.AiButton variant="contained" onClick={unGroupMode}>
              <UndoIcon />

              <Typography>그룹지어진 답변들을 다시 풀기</Typography>
            </S.AiButton>
          </Box>
        )}

        {/* 투표 수 및 설문형 텍스트 */}
        {!groupMode && (
          <Box sx={{ p: "10px", flexShrink: 0 }}>
            <Typography sx={{ font: "700 28px Pretendard", marginBottom: "10px" }}>
              설문형
            </Typography>

            <Typography sx={{ fontWeight: "600", color: "#888" }}>
              투표 수 : {currentSlide?.userData.length}
            </Typography>
          </Box>
        )}

        {groupMode && (
          <Box
            sx={{
              p: "15px 10px",
              flexShrink: 0,
              display: "flex",
              alignItems: "center",
              gap: "10px",
              mb: "5px",
            }}
          >
            <AutoAwesomeIcon sx={{ color: "#888" }} />

            <Typography sx={{ fontWeight: "600", color: "#888", fontSize: "24px" }}>
              {groupLength} 개의 그룹이 생성되었습니다.
            </Typography>
          </Box>
        )}

        {/* 설문형 그리드 */}
        {!groupMode && (
          <S.OpenEndedArea>
            {currentSlide?.userData.length > 0 && (
              <S.OpenEndedGrid container>
                {new Array(4).fill(0).map((_, i) => (
                  <S.OpenEndedItem item xs={3} key={i}>
                    {userData.map((data, index) => {
                      if (index % 4 === i)
                        return <S.OpenEndedBox key={index}>{data.answer}</S.OpenEndedBox>;
                      return;
                    })}
                  </S.OpenEndedItem>
                ))}
              </S.OpenEndedGrid>
            )}

            {/* 투표가 없을 때 텍스트 */}
            {currentSlide?.userData.length === 0 && (
              <S.OpenEndedWaitingBox>
                <S.WaitingText>참여자의 투표를 기다리는 중...</S.WaitingText>
              </S.OpenEndedWaitingBox>
            )}
          </S.OpenEndedArea>
        )}

        {groupMode && (
          <GroupContainer
            group={groupData}
            targetGroup={targetGroup}
            setTargetGroup={setTargetGroup}
          />
        )}

        {targetGroup && (
          <GroupDetail
            group={groupData[targetGroup]}
            setTarget={setTargetGroup}
            target={targetGroup}
          />
        )}
      </S.OpenEndedContent>

      {isGroupingStarted && <AiGenerationLoading />}
    </S.OpenEndedBody>
  );
};

const AiGenerationLoading = () => {
  return (
    <S.AiGenerationLoading>
      <Box>
        <video autoPlay loop muted src={LoadingVideo} style={{ width: "100%" }} />

        <S.BlinkingBox>
          <img src={sparkIcon} />

          <Typography>
            같은 카테고리끼리 답변들을 묶어내는 중... <br />
            <span>잠시만 기다려주세요. (최대 1분 소요)</span>
          </Typography>
        </S.BlinkingBox>
      </Box>
    </S.AiGenerationLoading>
  );
};

const GroupContainer = ({ group, targetGroup, setTargetGroup }) => {
  // const [target, setTarget] = useState(null);
  const [groups, setGroups] = useState([]);

  const openGrid = (e) => {
    setTargetGroup(e.currentTarget.id);
  };

  useEffect(() => {
    setGroups(group);
  }, [group]);

  return (
    <Box sx={{ flex: 1, position: "relative", overflow: "auto" }}>
      <GroupGrid groups={groups} openGrid={openGrid} />
    </Box>
  );
};

const GroupDetail = ({ group, setTarget, target }) => {
  const { gameId } = useParams();
  const SnackBar = useContext(SnackBarContext);
  const { playingData, currentSlideIndex, socket } = useContext(ManagerContext); // * 플레이데잉 데이터와 현재 슬라이드 인덱스를 가져옴

  const [groups, setGroups] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [category, setCategory] = useState("");

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const moveAnswer = (e) => {
    const fromGroup = group.groupId;
    const toGroup = e.currentTarget.id;

    socket.emit("moveAnswer", { gameId, fromGroup, toGroup, answerId: anchorEl.id }, (res) => {
      if (res.success) {
        SnackBar.setOpen(true);
        SnackBar.setText("답변을 이동했습니다.");
        SnackBar.setSeverity("success");
      }
    });
    setAnchorEl(null);
  };

  const EditCategory = () => {
    setEditMode(true);
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const changeCategory = () => {
    socket.emit("changeCategory", { gameId, groupId: group.groupId, category }, (res) => {
      if (res.success) {
        setEditMode(false);
      }
    });
  };

  useEffect(() => {
    let groups = playingData[currentSlideIndex].groupData;

    setGroups(groups || []);
  }, []);

  useEffect(() => {
    setCategory(group.category);
  }, [group]);

  // useEffect(() => {
  //   console.log(anchorEl?.id);
  // }, [anchorEl]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "absolute",
        bgcolor: "#fff",
        width: "100%",
      }}
    >
      <Button
        onClick={() => setTarget(null)}
        sx={{ font: "700 18px Pretendard", color: "#333", width: "max-content", mt: "10px" }}
      >
        <ArrowForwardIosIcon sx={{ transform: "rotate(180deg)", mr: "5px" }} />
        뒤로 가기
      </Button>

      <Box
        sx={{
          flex: 1,
          width: "calc(100% - 20px)",
          p: "20px",
          margin: "10px",
          boxSizing: "border-box",
          bgcolor: "#f5f5f5",
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #00000038",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", alignItems: "center", gap: "20px", mb: "10px" }}>
          <img src={folders[target % 9]} style={{ width: "50px" }} />

          <Box>
            {!editMode && (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Typography sx={{ font: "700 27px Pretendard", color: "#333", mb: "5px" }}>
                  {group.category}
                </Typography>

                {group.groupId !== "undefined" && (
                  <IconButton onClick={EditCategory}>
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            )}

            {editMode && (
              <TextField
                value={category}
                onChange={handleCategory}
                onBlur={changeCategory}
                autoFocus
                sx={{ bgcolor: "#fff", borderRadius: "10px", border: "2px solid #aaa" }}
                InputProps={{
                  style: { fontSize: "24px", fontWeight: "600" },
                }}
              />
            )}

            <Typography sx={{ color: "#888" }}>
              {group.answers.length}개의 답변이 있습니다.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ flex: 1, position: "relative" }}>
          <Box sx={{ position: "absolute", width: "100%", height: "100%", overflow: "auto" }}>
            <Grid container rowGap="20px">
              {group.answers.map((answer, index) => (
                <Grid item xs={3} key={index} sx={{ p: "10px" }}>
                  <Box
                    sx={{
                      p: "20px",
                      bgcolor: "#ddd",
                      pr: "30px",
                      position: "relative",
                      "&:hover": { bgcolor: "#b6e3e9" },
                      transition: "background-color 0.5s",
                    }}
                  >
                    <Typography sx={{ color: "#333", fontSize: "24px", fontWeight: 600 }}>
                      {answer?.answer}
                    </Typography>

                    <IconButton
                      sx={{ position: "absolute", top: 5, right: 5 }}
                      onClick={handleMenu}
                      id={`${answer.answerId}`}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ))}

              {Boolean(anchorEl) && (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  disablePortal
                >
                  {groups &&
                    groups.map((item, index) => (
                      <MenuItem
                        key={item.groupId}
                        disabled={item.groupId === group.groupId}
                        sx={{ display: item.groupId === "undefined" ? "none" : "" }}
                        onClick={moveAnswer}
                        id={item.groupId}
                      >
                        <img src={folders[index % 9]} style={{ width: "20px" }} />

                        <Typography sx={{ ml: "10px", fontWeight: 600 }}>
                          {item.category}
                        </Typography>
                        <Typography sx={{ ml: "10px", color: "#888" }}>(으)로 이동</Typography>
                      </MenuItem>
                    ))}
                  <MenuItem onClick={moveAnswer} id="new">
                    <AddIcon />

                    <Typography sx={{ ml: "10px", fontWeight: 600 }}>새로운 그룹 생성</Typography>
                  </MenuItem>
                </Menu>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const GroupGrid = ({ groups, openGrid }) => {
  // console.log(groups);
  return (
    <Grid container sx={{ position: "absolute", width: "100%" }} rowGap="20px">
      {groups.map((data, index) => (
        <Grid
          item
          xs={4}
          key={index}
          sx={{
            p: "0 10px",
            display: data.groupId === "undefined" && data.answers.length === 0 ? "none" : "",
          }}
        >
          <Box
            onClick={openGrid}
            id={index}
            sx={{
              display: "flex",
              bgcolor: "#f5f5f5",
              p: "20px",
              alignItems: "center",
              gap: "15px",
              height: "200px",
              "&:hover": {
                bgcolor: "#e5e5e5",
                cursor: "pointer",
                "> img": {
                  transform: "translateY(-10px)",
                  transition: "all 0.5s",
                },
              },
            }}
          >
            <img src={folders[index % 9]} alt="folder" style={{ width: "50px", flexShrink: 0 }} />

            <Box
              sx={{
                position: "relative",
                flex: 1,
                height: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography
                  sx={{ font: "700 24px Pretendard", color: "#333", wordBreak: "break-all" }}
                >
                  {data.category}
                </Typography>

                <Typography sx={{ color: "#888" }}>
                  {data.answers.length}개의 답변이 있습니다.
                </Typography>

                <Typography
                  sx={{
                    color: "#666",
                    mt: "10px",
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                  noWrap
                >
                  {data.answers?.map((answer, index) => (
                    <span key={index}>
                      {`"${answer?.answer}"`}
                      {index !== data.answers.length - 1 && ", "}
                    </span>
                  ))}
                </Typography>
              </Box>
            </Box>

            <ArrowForwardIosIcon />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
