import MainImg from "../../../../src/assets/images/HexagramMainImg.png";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import * as S from "./styles";
import { Box, Typography } from "@mui/material";

export default (props) => {
  const { slotName } = useContext(UserContext);

  return (
    <S.Intro>
      {/* 제목 */}
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
        <Typography sx={{ font: "bold 20px Pretendard", mb: "30px" }}>{slotName}</Typography>
      </Box>
      {/* 예시 이미지 - 추후에 이미지 받으면 삽입 예정 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <img
          src={MainImg}
          alt="Main"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      {/* 핵사그램 이름 */}
      <Typography
        sx={{ font: "700 28px Pretendard", mt: "24px", textAlign: "center", color: "#c33c3c" }}
      >
        라이브 핵사그램
      </Typography>
      {/* 유저 정보 입력 창으로 이동하는 버튼 - props로 이동 함수 받아서 사용 */}
      <Box
        onClick={props.gotoUserInfo}
        sx={{
          mt: "25px",
          font: "500 18px Pretendard",
          width: "195px",
          height: "58px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#c33c3c",
          borderRadius: "10px",
          color: "#fff",
          cursor: "pointer",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: "#a32e2e",
            transform: "scale(1.03)", // 크기 조정
          },
          "&:active": {
            backgroundColor: "#892525",
            transform: "scale(0.97)", // 크기 조정
          },
        }}
      >
        정보 입력하기
      </Box>
    </S.Intro>
  );
};
