import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import config from "../config";

const Context = createContext();

const Provider = ({ children }) => {
  const today = new Date();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { TossPayments } = window;
  const [cookies] = useCookies();
  const clientKey = config.TOSS_PAYMENT_API_CLIENT_KEY;

  const [plan, setPlan] = useState(null);
  const [month, setMonth] = useState(null);
  const [date, setDate] = useState(null);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [finalPrice, setFinalPrice] = useState(null);
  const [isCouponModalOpened, setCouponModalOpened] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [coupons, setCoupons] = useState([]);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [isChecked, setChecked] = useState([false, false]);
  const [card, setCard] = useState("신용카드");
  const [period, setPeriod] = useState("0");
  const [paymentFrequency, setPaymentFrequency] = useState("default");
  const [groupUserCount, setGroupUserCount] = useState("default");
  const [usedCoupon, setUsedCoupon] = useState(null);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [user, setUser] = useState(null);

  const value = {
    V: {
      plan,
      month,
      date,
      price,
      discount,
      finalPrice,
      isCouponModalOpened,
      couponCode,
      coupons,
      name,
      phone,
      company,
      isChecked,
      buttonDisabled,
      card,
      paymentFrequency,
      user,
      groupUserCount,
      usedCoupon,
      period,
    },

    F: {
      goBackPage: () => {
        navigate("/plan");
      },
      setDiscount,
      handleCouponModal: () => {
        if (state.plan === "period") {
          if (period === "0") {
            alert("기간을 선택해주세요.");
          } else {
            setCouponModalOpened((prev) => !prev);
          }

          return;
        }

        if (paymentFrequency === "default") {
          alert("결제 주기를 선택해주세요.");
          return;
        }
        setCouponModalOpened((prev) => !prev);
      },
      handleCouponCode: (e) => {
        setCouponCode(e.target.value);
      },
      checkCouponCode: () => {
        axios.get("/coupon/checkCouponCode", { params: { code: couponCode } }).then((response) => {
          const result = response.data;

          if (result.success) {
            for (var i = 0; i < coupons.length; i++) {
              if (coupons[i]._id === result.object._id) return;
            }
            setCoupons([...coupons, result.object]);
          } else {
            alert("쿠폰을 찾을 수 없습니다.");
          }
        });
      },
      selectCoupon: (coupon) => () => {
        const now = new Date();
        const ExpiredAt = new Date(coupon.expiredDate);
        ExpiredAt.setHours(23, 59, 59, 999);

        if (!coupon.unlimited) {
          if (ExpiredAt.getTime() < now.getTime()) {
            alert("쿠폰이 만료되었습니다.");
            return;
          }
        }
        if (user.status === "Group") {
          if (!coupon.applicatedPlans.includes("group-basic-yearly")) {
            alert("해당 쿠폰은 이 결제 주기에 사용할 수 없습니다.");
            return;
          }
        } else if (user.status === "Personal") {
          if (period === "0") {
            if (!coupon.applicatedPlans.includes(`basic-${paymentFrequency}`)) {
              alert("해당 쿠폰은 이 결제 주기에 사용할 수 없습니다.");
              return;
            }
          } else {
            if (!coupon.applicatedPlans.includes(`basic-period`)) {
              alert("해당 쿠폰은 이 결제 주기에 사용할 수 없습니다.");
              return;
            }
          }
        }

        if (coupon.discountType === "fixed") {
          setDiscount(coupon.discount);
        } else if (coupon.discountType === "percentage") {
          if (user.status === "Group") {
            setDiscount(price * (coupon.discount / 100));
          } else {
            setDiscount(price * (coupon.discount / 100));
          }
        }
        setUsedCoupon(coupon);
        setCouponModalOpened(false);
      },
      handleName: (e) => {
        setName(e.target.value);
      },
      handlePhone: (e) => {
        const regex = /^[0-9\b -]{0,13}$/;
        if (!regex.test(e.target.value)) return;

        setPhone(e.target.value);
      },
      handleCompany: (e) => {
        setCompany(e.target.value);
      },
      handleChecked: (e) => {
        if (e.currentTarget.id === "0") {
          setChecked([!isChecked[0], isChecked[1]]);
        } else {
          setChecked([isChecked[0], !isChecked[1]]);
        }
        // setChecked((prev) => !prev);
      },
      startBilling: () => {
        const depth = window.location.href.split("/");
        const url = depth[0] + "//" + depth[2];
        var tosspayments = TossPayments(clientKey);
        // const paymentWidget = window.paymentWidget(
        //   "test_gck_XZYkKL4MrjLpw7x6DGdB30zJwlEW",
        //   "ghkgkjh7668"
        // );

        if (state.plan === "period") {
          if (finalPrice === 0) {
            const params = {
              period,
              auth: cookies.auth,
              plan: "basic",
              planType: "period",
              company,
              phone,
            };

            axios.post("/api/payment/free-enroll", params).then((res) => {
              const { success, user } = res.data;
              const state = {
                orderName: `위드플러스 라이브 베이직 (${period}개월)`,
                StartedAt: user.StartedAt,
                ExpiredAt: user.ExpiredAt,
                totalAmount: 0,
                receipt: "none",
              };

              navigate("/payment/checkout/success", { replace: true, state });
            });
            return;
          }

          navigate("checkout", {
            state: {
              finalPrice,
              period,
              name,
              phone,
              company,
              orderName: `위드플러스 라이브 베이직 (${period}개월)`,
              auth: cookies.auth,
            },
            replace: true,
          });
          return;
        }

        tosspayments
          .requestBillingAuth("카드", {
            customerKey: user._id,
            successUrl:
              url +
              `/payment/success?price=${price}&plan=${plan}&month=${month}&date=${date}&discount=${discount}&finalPrice=${finalPrice}&name=${name}&phone=${phone}&company=${company}&groupUserCount=${groupUserCount}` +
              `&usedCoupon=${usedCoupon ? usedCoupon._id : "undefined"}`,
            failUrl: url + "/payment/checkout/fail",
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handleCard: (e) => {
        setCard(e.target.value);
      },
      handlePeriod: (e) => {
        setPeriod(e.target.value);
      },
      handlePaymentFrequency: (e) => {
        setPaymentFrequency(e.target.value);
      },
      handleGroupUserCount: (e) => {
        setGroupUserCount(e.target.value);
      },
    },
  };

  useEffect(() => {
    if (!state) return;

    cookies.auth &&
      axios.get("/api/user", { params: { auth: cookies.auth } }).then((res) => {
        if (!res.data.success) return;

        const user = res.data.user;

        setUser(user);
      });

    // switch (state.plan) {
    //   case "basic_month":
    //     setPlan("monthly");
    //     setPrice(9_900);
    //     break;
    //   case "basic_year":
    //     setPlan("yearly");
    //     setPrice(99_000);
    //     break;
    //   default:
    //     break;
    // }

    // setMonth(today.getMonth() + 1);
    // setDate(today.getDate());
  }, []);

  useEffect(() => {
    if (!user) return;

    if (user.status === "Group") {
      setFinalPrice(
        price * groupUserCount - discount * groupUserCount < 0
          ? 0
          : price * groupUserCount - discount * groupUserCount
      );
    } else {
      if (price - discount >= 0 && price - discount < 200) {
        setFinalPrice(0);
      } else {
        setFinalPrice(price - discount < 0 ? 0 : price - discount);
      }
    }
    // if (!plan || !price) return;
  }, [price, discount, groupUserCount]);

  useEffect(() => {
    if (!user) return;

    if (user.status === "Group") {
      if (
        name !== "" &&
        phone !== "" &&
        isChecked.every((item) => item === true) &&
        card !== "신용카드" &&
        groupUserCount !== "default"
      ) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else if (user.status === "Personal") {
      if (state.plan === "period") {
        if (
          name !== "" &&
          phone !== "" &&
          isChecked.every((item) => item === true) &&
          period !== "0"
        ) {
          setButtonDisabled(false);
        } else {
          setButtonDisabled(true);
        }

        return;
      }

      if (
        name !== "" &&
        phone !== "" &&
        isChecked.every((item) => item === true) &&
        card !== "신용카드" &&
        paymentFrequency !== "default"
      ) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  }, [name, phone, isChecked, card, paymentFrequency, groupUserCount, period]);

  useEffect(() => {
    if (!isCouponModalOpened) setCouponCode("");
  }, [isCouponModalOpened]);

  useEffect(() => {
    if (!user) return;

    const now = new Date();
    const ExpiredAt = new Date(user.ExpiredAt);
    const nextPaymentAt = new Date(ExpiredAt);
    nextPaymentAt.setDate(nextPaymentAt.getDate() + 1);

    if (user.status === "Group") {
      setPaymentFrequency("yearly");
      setPlan("yearly");
    }

    if (ExpiredAt > now) {
      setMonth(nextPaymentAt.getMonth() + 1);
      setDate(nextPaymentAt.getDate());
    } else {
      now.setDate(now.getDate() - 1);
      setMonth(now.getMonth() + 1);
      setDate(now.getDate());
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;

    let price = 99_000;

    if (user.status === "Group") {
      if (groupUserCount >= 5 && groupUserCount < 10) {
        price *= 0.9;
      } else if (groupUserCount >= 10 && groupUserCount < 20) {
        price *= 0.8;
      } else if (groupUserCount >= 20 && groupUserCount < 50) {
        price *= 0.7;
      } else if (groupUserCount === 50) {
        price *= 0.5;
      } else {
        price *= 1;
      }

      if (usedCoupon) {
        if (usedCoupon.discountType === "fixed") {
          setDiscount(usedCoupon.discount);
        } else if (usedCoupon.discountType === "percentage") {
          setDiscount(price * (usedCoupon.discount / 100));
        }
      }

      setPrice(price);
    }
  }, [groupUserCount]);

  useEffect(() => {
    if (!user) return;

    if (user.status === "Personal") {
      if (paymentFrequency === "monthly") {
        setPrice(9_900);
        setPlan("monthly");
      } else if (paymentFrequency === "yearly") {
        setPrice(99_000);
        setPlan("yearly");
      } else {
        setPrice(0);
      }
    }
  }, [paymentFrequency]);

  useEffect(() => {
    if (!user) return;

    if (period === "0") {
      setPrice(0);
    } else if (period === "12") {
      setPrice(99_000);
    } else {
      setPrice(9_900 * period);
      // setFinalPrice(9_900 * period);
    }
  }, [period]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
