import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import TermOfUse from "template/TermOfUse";
import { QRCode } from "react-qr-svg";
import { Cropper } from "react-cropper";

import "cropperjs/dist/cropper.css";
import { CustomButton as _Button, CustomLoadingButton } from "./Button";

import {
  Box,
  IconButton,
  Divider,
  Grid,
  Button,
  Typography,
  Modal,
  TextField,
  Tooltip,
  CircularProgress,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import StartIcon from "@mui/icons-material/Start";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import logo from "../assets/images/wLiveLogo(Red).png";
import boardScreenShot from "assets/images/board-screenshot.png";

import { QuizContext } from "views/LiveQuiz/contexts/Quiz";
import { ManagerContext } from "views/LivePolling/contexts/ManagerContext";
import { useParams } from "react-router-dom";
import { SnackBarContext, SnackBarProvider } from "./SnackBar/ContextAPI";
import { Textfield } from "./Textfield";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// * Modal 기본 css 세팅
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 1,
};

// * 쿠폰 Modal
export const CouponModal = (props) => {
  const { open, handleModal, handleDiscount } = props;

  const [couponCode, setCouponCode] = useState("");
  const [coupons, setCoupons] = useState([]);

  // * 쿠폰 코드 value onChange
  const handleCouponCode = (e) => {
    setCouponCode(e.target.value);
  };
  // * 쿠폰 확인 함수
  const checkCouponCode = () => {
    axios.get("/coupon/checkCouponCode", { params: { code: couponCode } }).then((response) => {
      const result = response.data;

      if (result.success) {
        for (var i = 0; i < coupons.length; i++) {
          if (coupons[i]._id === result.object._id) return;
        }
        setCoupons([...coupons, result.object]);
      }
    });
  };
  return (
    <Modal open={open} onClose={handleModal} sx={{ "&:focus-visible": { outline: "none" } }}>
      <Box
        sx={{
          ...style,
          width: "400px",
          height: "300px",
          border: 0,
          "&:focus": { outline: "none" },
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "#cdcdcd", pb: 1 }}>
          {/* Header */}
          <Grid container>
            <Grid item xs={1}></Grid>
            {/* Title */}
            <Grid item xs={10} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ fontSize: "24px" }}>쿠폰 등록</Typography>
            </Grid>
            {/* Close IconButton */}
            <Grid item xs={1}>
              <IconButton onClick={handleModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {/* 쿠폰 등록 Container */}
        <Box sx={{ my: "8px" }}>
          <Typography sx={{ fontFamily: "NotoSansKR-Medium" }}>쿠폰 등록하기</Typography>
          <Box>
            <Grid container columnSpacing={1}>
              <Grid item xs={9}>
                <TextField
                  size="small"
                  fullWidth
                  value={couponCode}
                  onChange={handleCouponCode}
                  placeholder="쿠폰번호를 입력해주세요."
                  InputProps={{
                    style: {
                      fontSize: "15px",
                      fontFamily: "NotoSansKR-Medium",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" onClick={checkCouponCode}>
                  쿠폰 등록
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* 보유 쿠폰 Container */}
        <Box>
          <Typography sx={{ fontFamily: "NotoSansKR-Medium" }}>보유중인 쿠폰</Typography>
          <Divider />
          {coupons?.map((coupon) => (
            <Box
              key={coupon._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: 1,
                my: 1,
                borderRadius: "6px",
                alignItems: "center",
                border: 1,
                borderColor: "#00000050",
              }}
            >
              <Box>
                <Typography>{coupon.content.title}</Typography>
                <Typography sx={{ fontSize: "13px", color: "#888888" }}>
                  {coupon.content.discount.toLocaleString("ko-KR")}원
                </Typography>
              </Box>

              <Box>
                <Tooltip title="사용하기" arrow>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleModal();
                      handleDiscount(coupon.content.discount);
                    }}
                  >
                    <StartIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

//0729 지원 작업
export const DeleteHexagramModal = (props) => {
  const { open, handleModal, deleteHexagram, id, count } = props;

  const [loading, setLoading] = useState(false);

  const handleLoading = () => {
    setLoading(true);
  };

  return (
    <Modal open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ p: 4, backgroundColor: "white", borderRadius: "10px" }}>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ font: "bold 24px NotoSansKR-Medium" }}>
            {id ? "해당 라이브 핵사그램을" : `${count}개의 데이터를`} 삭제하시겠습니까?
          </Typography>

          <Typography sx={{ fontFamily: "NotoSansKR-Medium" }}>
            삭제되는 데이터는 영구적으로 삭제되고 복구가 불가능합니다.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "auto" }}>
          <CustomLoadingButton
            sx={{ mx: "3px" }}
            onClick={() => {
              deleteHexagram(id ? id : undefined);
              handleLoading();
            }}
            loading={loading}
          >
            삭제
          </CustomLoadingButton>

          <CustomLoadingButton
            sx={{
              backgroundColor: "#909090",
              mx: "3px",
              "&:hover": { backgroundColor: "#707070" },
            }}
            onClick={handleModal}
            loading={loading}
          >
            취소
          </CustomLoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};


export const DeletePollModal = (props) => {
  const { open, handleModal, deletePoll, id, count } = props;

  const [loading, setLoading] = useState(false);

  const handleLoading = () => {
    setLoading(true);
  };

  return (
    <Modal open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ p: 4, backgroundColor: "white", borderRadius: "10px" }}>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ font: "bold 24px NotoSansKR-Medium" }}>
            {id ? "해당 라이브폴링을" : `${count}개의 데이터를`} 삭제하시겠습니까?
          </Typography>

          <Typography sx={{ fontFamily: "NotoSansKR-Medium" }}>
            삭제되는 데이터는 영구적으로 삭제되고 복구가 불가능합니다.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "auto" }}>
          <CustomLoadingButton
            sx={{ mx: "3px" }}
            onClick={() => {
              deletePoll(id ? id : undefined);
              handleLoading();
            }}
            loading={loading}
          >
            삭제
          </CustomLoadingButton>

          <CustomLoadingButton
            sx={{
              backgroundColor: "#909090",
              mx: "3px",
              "&:hover": { backgroundColor: "#707070" },
            }}
            onClick={handleModal}
            loading={loading}
          >
            취소
          </CustomLoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

// * 라이브폴링 삭제 모달
export const DeleteBoardModal = (props) => {
  const { open, handleModal, list, action } = props; // * open : 모달 오픈 여부, handleModal : 모달 닫기 함수, list : 삭제할 데이터 리스트, action : 삭제 함수

  const [loading, setLoading] = useState(false); // * 삭제 버튼 로딩 여부

  // * 삭제 버튼 클릭 시 로딩 상태로 변경
  const handleLoading = () => {
    setLoading(true);
  };

  return (
    <Modal open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ p: 4, backgroundColor: "white", borderRadius: "10px" }}>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ font: "bold 28px Pretendard", mb: "10px" }}>
            {list?.length > 1 && `${list.length}개의 라이브 보드를 삭제하시겠습니까?`}

            {list?.length === 1 && `해당 라이브 보드를 삭제하시겠습니까?`}
          </Typography>

          <Typography sx={{ fontFamily: "Pretendard", fontWeight: "600" }}>
            삭제되는 데이터는 영구적으로 삭제되고 복구가 불가능합니다.
          </Typography>
        </Box>

        {/* 버튼 박스 */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "auto" }}>
          <CustomLoadingButton
            sx={{ mx: "3px" }}
            onClick={() => {
              action();
              handleLoading();
            }}
            loading={loading}
          >
            삭제
          </CustomLoadingButton>

          <CustomLoadingButton
            sx={{
              backgroundColor: "#909090",
              mx: "3px",
              "&:hover": { backgroundColor: "#707070" },
            }}
            onClick={handleModal}
            loading={loading}
          >
            취소
          </CustomLoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const ResetPollModal = (props) => {
  const { open, handleModal, resetPoll, id } = props;
  const [loading, setLoading] = useState(false);

  const handleLoading = () => {
    setLoading(true);
  };

  return (
    <Modal
      open={open}
      onClose={handleModal}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& :focus-visible": {
          outline: "none ",
        },
      }}
    >
      <Box sx={{ p: 4, backgroundColor: "white", borderRadius: "10px" }}>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ font: "bold 24px NotoSansKR-Medium" }}>
            해당 라이브폴링을 리셋하시겠습니까?
          </Typography>
          <Typography sx={{ fontFamily: "NotoSansKR-Medium" }}>
            데이터는 영구적으로 삭제되고 복구가 불가능합니다.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "auto" }}>
          <CustomLoadingButton
            sx={{ mx: "3px" }}
            onClick={() => {
              resetPoll(id);
              handleLoading();
            }}
            loading={loading}
          >
            리셋
          </CustomLoadingButton>

          <CustomLoadingButton
            sx={{
              backgroundColor: "#909090",
              mx: "3px",
              "&:hover": { backgroundColor: "#707070" },
            }}
            onClick={handleModal}
            loading={loading}
          >
            취소
          </CustomLoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const GoBackModal = (props) => {
  const { open, onClose, goBack } = props;

  const [animation, setAnimation] = useState({
    width: "0px",
    height: "0px",
    p: 0,
    border: 0,
  });

  useEffect(() => {
    if (open) {
      setAnimation({
        width: "200px",
        height: "150px",
      });
    } else {
      setAnimation({
        width: "0px",
        height: "0px",
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "#00000000",
          },
        },
      }}
    >
      <Box
        sx={{
          ...style,
          ...animation,
          backgroundColor: "#888888fc",
          p: 2,
          top: "20%",
          left: "50%",
          overflow: "hidden",
          transition: "width 0.15s, height 0.15s",
          border: "1px solid #88888830",
          textAlign: "center",
          boxShadow: "10px 20px 25px #00000030",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "50px",
            height: "50px",
            mx: "auto",
            mb: "20px",
            borderRadius: "10px",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} style={{ width: "70%" }} />
        </Box>

        <Typography sx={{ font: "14px NotoSansKR-Medium", color: "white", mb: "5px" }} noWrap>
          이 사이트에서 나가시겠습니까?
        </Typography>
        <Typography sx={{ font: "12px NotoSansKR-Medium", color: "white", mb: "10px" }} noWrap>
          변경사항이 저장되지 않을 수 있습니다.
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "100px",
              backgroundColor: "#ababab",
              mr: "2px",
              fontFamily: "NotoSansKR-Medium",
              "&:hover": { backgroundColor: "#ababab" },
            }}
            onClick={onClose}
          >
            취소
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100px",
              backgroundColor: "rgb(21,91,255)",
              ml: "2px",
              fontFamily: "NotoSansKR-Medium",
              "&:hover": { backgroundColor: "rgb(21,91,255)" },
            }}
            onClick={goBack}
          >
            나가기
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const GuideModal = (props) => {
  const { open, handleModal, target } = props;

  return (
    <Modal
      open={open}
      onClose={handleModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto" }}
    >
      <Box sx={{ p: 4, backgroundColor: "white", borderRadius: "10px" }}>
        {/* 일단 이미지로 넣기... */}
        {/* <Document file={livePollingGuidePDF}>
            <Page pageNumber={1} />
          </Document> */}
        <div
          style={{
            width: "70vw",
            height: "80vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          <img
            src={target}
            style={{
              position: "absolute",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <Box sx={{ display: "flex", justifyContent: "center", width: "auto" }}>
          {/* eslint-disable-next-line */}
          <_Button
            sx={{
              backgroundColor: "#909090",
              mx: "3px",
              "&:hover": { backgroundColor: "#707070" },
            }}
            onClick={handleModal}
          >
            확인
          </_Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const TermOfUseModal = (props) => {
  const { open, handleModal, refund } = props;

  return (
    <Modal
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto" }}
    >
      <Box sx={{ p: 4, backgroundColor: "white", borderRadius: "10px" }}>
        <div
          style={{
            width: "70vw",
            height: "80vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          <TermOfUse refund={refund} />
        </div>
        <Box sx={{ display: "flex", justifyContent: "center", width: "auto" }}>
          {/* eslint-disable-next-line */}
          <_Button
            sx={{
              backgroundColor: "#909090",
              mx: "3px",
              "&:hover": { backgroundColor: "#707070" },
            }}
            onClick={handleModal}
          >
            확인
          </_Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const QRCodeWideModal = (props) => {
  const { open, handleModal, ModalQRCode } = props;

  return (
    <Modal
      open={open}
      onClose={handleModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disablePortal
    >
      <Box sx={{ width: "60%", height: "80%", outline: "none", cursor: "pointer" }}>
        <QRCode
          style={{ width: "100%", height: "100%" }}
          value={ModalQRCode}
          onClick={handleModal}
        />
      </Box>
    </Modal>
  );
};

export const UploadImageModal = (props) => {
  const { currentSlide, updateCurrentSlide } = useContext(QuizContext);
  const { open, handleModal, type, selectedAnswerId } = props;

  const imageInputRef = useRef(null);
  const cropperRef = useRef(null);

  const [fileName, setFileName] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [croppedData, setCroppedData] = useState(null);

  const uploadImage = (e) => {
    const maxSize = 1024 * 1024 * 10;
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > maxSize) {
      alert("10MB 이하의 이미지만 업로드 가능합니다.");
      return;
    }

    setFileName(file.name);

    const reader = new FileReader();

    reader.readAsDataURL(file);
    // console.log(reader);

    reader.onloadend = () => {
      setUploadedImage(reader.result);
      // updateCurrentSlide({ media: reader.result });
    };
  };

  const openImageInput = (e) => {
    imageInputRef.current.click();
  };

  const deleteImage = () => {
    if (window.confirm("이미지를 삭제하시겠습니까?")) {
      setFileName("");
      setUploadedImage(null);

      if (type === "question") {
        updateCurrentSlide({
          mediaCroppedObject: null,
          mediaName: null,
          mediaObject: null,
          croppedData: null,
        });
      } else if (type === "answer") {
        let newAnswers = [];

        currentSlide.answers.forEach((answer) => {
          if (answer.id === selectedAnswerId) {
            newAnswers.push({
              ...answer,
              mediaCroppedObject: null,
              mediaName: null,
              mediaObject: null,
              croppedData: null,
            });
          } else {
            newAnswers.push(answer);
          }
        });

        updateCurrentSlide({
          answers: newAnswers,
        });
      }
    }
  };

  const getCroppedData = () => {
    if (typeof cropperRef.current.cropper === "undefined") return;

    if (type === "question") {
      updateCurrentSlide({
        mediaCroppedObject: cropperRef.current.cropper.getCroppedCanvas().toDataURL(),
        mediaName: fileName,
        mediaObject: uploadedImage,
        croppedData: cropperRef.current.cropper.getData(),
      });
    } else if (type === "answer") {
      let newAnswers = [];

      currentSlide.answers.forEach((answer) => {
        if (answer.id === selectedAnswerId) {
          newAnswers.push({
            ...answer,
            mediaCroppedObject: cropperRef.current.cropper.getCroppedCanvas().toDataURL(),
            mediaName: fileName,
            mediaObject: uploadedImage,
            croppedData: cropperRef.current.cropper.getData(),
          });
        } else {
          newAnswers.push(answer);
        }
      });

      updateCurrentSlide({
        answers: newAnswers,
      });
    }

    handleModal();
  };

  useEffect(() => {
    if (type === "question") {
      setUploadedImage(currentSlide.mediaObject);
      setFileName(currentSlide.mediaName);

      let croppedData = {};
      for (let key in currentSlide.croppedData) {
        croppedData[key] = Number(currentSlide.croppedData[key]);
      }
      setCroppedData(croppedData);
    } else if (type === "answer") {
      let selectedAnswer = currentSlide.answers.filter(
        (answer) => answer.id === selectedAnswerId
      )[0];
      setUploadedImage(selectedAnswer.mediaObject);
      setFileName(selectedAnswer.mediaName);

      let croppedData = {};
      for (let key in selectedAnswer.croppedData) {
        croppedData[key] = Number(selectedAnswer.croppedData[key]);
      }
      setCroppedData(croppedData);
    }
  }, [type, currentSlide]);

  return (
    <Modal
      open={open}
      onClose={handleModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          position: "relative",
          width: "60%",
          height: "80%",
          bgcolor: "white",
          p: "20px 10px",
          display: "flex",
          gap: "20px",
          flexDirection: "column",
          outline: "none",
        }}
      >
        <Box sx={{ position: "absolute", right: "10px", top: "10px" }}>
          <IconButton onClick={handleModal} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={{ font: "bold 30px Pretendard", textAlign: "center" }}>
          이미지 업로드
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            border: "1px solid #ddd",
          }}
        >
          {uploadedImage ? (
            <Box
              sx={{
                width: "100%",
                height: 0,
                minHeight: "100%",
                boxSizing: "border-box",
              }}
            >
              <Cropper
                style={{ height: "100%", width: "100%" }}
                ref={cropperRef}
                aspectRatio={16 / 9}
                src={uploadedImage}
                viewMode={0}
                responsive
                autoCropArea={1}
                checkOrientation={false}
                data={croppedData}
              />
            </Box>
          ) : (
            <Box
              sx={{
                cursor: "pointer",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ddd",
                },
              }}
              onClick={openImageInput}
            >
              <FileUploadIcon sx={{ fontSize: "75px" }} />
              <Typography sx={{ font: "bold 20px Pretendard", textAlign: "center" }}>
                클릭하여 이미지 업로드 <br />
                (최대 10MB의 jpg, png 파일)
              </Typography>
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                ref={imageInputRef}
                style={{ display: "none" }}
                onChange={uploadImage}
              />
            </Box>
          )}
        </Box>

        {uploadedImage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "30px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "#777",
                font: "bold 18px Pretendard",
                "&:hover": { bgcolor: "#666" },
                lineHeight: "1.5",
              }}
              onClick={deleteImage}
            >
              사진 지우기
            </Button>

            <Button
              variant="contained"
              sx={{
                bgcolor: "#aaa",
                font: "bold 18px Pretendard",
                "&:hover": { bgcolor: "#999" },
                lineHeight: "1.5",
              }}
              onClick={() => setUploadedImage(null)}
            >
              다시 사진 고르기
            </Button>

            <Button
              variant="contained"
              sx={{ font: "bold 18px Pretendard", lineHeight: "1.5" }}
              onClick={getCroppedData}
            >
              업로드
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export const LoadingModal = (props) => {
  const { open } = props;

  return (
    <Modal
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disablePortal
    >
      <Box
        sx={{
          width: "100px",
          height: "100px",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
        }}
      >
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export const QnAModal = (props) => {
  const { open, onClose, list } = props;
  const { gameId } = useParams();

  const { socket, setQnA } = useContext(ManagerContext);
  const { setOpen, setText, setSeverity } = useContext(SnackBarContext);

  const [value, setValue] = useState("not complete");

  const [notCompletedQuestions, setNotCompletedQuestions] = useState([]);
  const [completedQuestions, setCompletedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const handleValue = (_, newValue) => {
    setValue(newValue);
  };

  const handleCurrentQuestion = (e) => {
    const id = e.currentTarget.id;
    const question = list.filter((item) => item.id === id)[0];

    setCurrentQuestion(question);
  };

  const setComplete = () => {
    socket.emit("completeQuestion", { gameId, questionId: currentQuestion.id }, (res) => {
      const { success, poll } = res;

      if (success) {
        let newCurrentQuestion = poll.QnA.filter((item) => item.id === currentQuestion.id)[0];

        setQnA(poll.QnA);
        setOpen(true);
        setSeverity("success");
        setText("질문이 성공적으로 완료되었습니다.");
        setCurrentQuestion(newCurrentQuestion);
      }
    });
  };

  useEffect(() => {
    if (list) {
      const notCompleted = list.filter((item) => !item.completed);
      const completed = list.filter((item) => item.completed);

      setNotCompletedQuestions(notCompleted);
      setCompletedQuestions(completed);
    }
  }, [list]);

  return (
    <Modal open={open} disablePortal onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "70%",
          bgcolor: "#fff",
          borderRadius: "20px",
          p: "20px",
          outline: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ flex: 1, display: "flex", overflow: "auto" }}>
          <Box
            sx={{
              width: "15%",
              borderRight: "1px solid #eee",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Tabs centered value={value} onChange={handleValue}>
                <Tab
                  label={
                    <span>
                      답변 미완료 <br />
                      {`(${notCompletedQuestions.length})`}
                    </span>
                  }
                  value={"not complete"}
                />
                <Tab
                  label={
                    <span>
                      답변 완료 <br />
                      {`(${completedQuestions.length})`}
                    </span>
                  }
                  value={"complete"}
                />
              </Tabs>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                mt: "20px",
                alignItems: "center",
                overflow: "auto",
              }}
            >
              {value === "not complete" &&
                notCompletedQuestions.map((item) => (
                  <Box
                    key={item.id}
                    id={item.id}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px solid #eee",
                      borderColor: currentQuestion?.id === item.id ? "rgb(21,91,255)" : "#eee",
                      bgcolor: currentQuestion?.id === item.id ? "rgba(21,91,255,0.2)" : "#fff",
                      borderRadius: "10px",
                      width: "85%",
                      p: "10px",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      wordBreak: "break-all",
                    }}
                    onClick={handleCurrentQuestion}
                  >
                    {item.question}
                  </Box>
                ))}

              {value === "complete" &&
                completedQuestions.map((item) => (
                  <Box
                    key={item.id}
                    id={item.id}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px solid #eee",
                      borderColor: currentQuestion?.id === item.id ? "rgb(21,91,255)" : "#eee",
                      bgcolor: currentQuestion?.id === item.id ? "rgba(21,91,255,.2)" : "#fff",
                      borderRadius: "10px",
                      width: "90%",
                      p: "10px",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      wordBreak: "break-all",
                    }}
                    onClick={handleCurrentQuestion}
                  >
                    {item.question}
                  </Box>
                ))}
            </Box>
          </Box>

          <Box sx={{ width: "85%" }}>
            {currentQuestion && (
              <Box
                sx={{
                  height: "100%",
                  p: "10px",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {currentQuestion.completed && (
                  <Box
                    sx={{
                      bgcolor: "#00ff00",
                      width: "max-content",
                      p: "5px 10px",
                      font: "600 16px Pretendard",
                      borderRadius: "10px",
                      mb: "30px",
                    }}
                  >
                    답변 완료
                  </Box>
                )}

                <Typography sx={{ font: "bold 44px Pretendard", flex: 1, wordBreak: "break-all" }}>
                  {currentQuestion.question}
                </Typography>

                {!currentQuestion.completed && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      sx={{ font: "600 24px Pretendard", p: "15px" }}
                      onClick={setComplete}
                    >
                      답변완료 하기
                    </Button>
                  </Box>
                )}
              </Box>
            )}

            {!currentQuestion && (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ font: "bold 40px Pretendard" }}>질문을 선택해주세요.</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export const ResetModal = (props) => {
  const { open, onClose } = props;
  const { gameId } = useParams();

  const { socket } = useContext(ManagerContext);
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [type, setType] = useState("current");

  const handleType = (e) => {
    setType(e.target.value);
  };

  const reset = () => {
    socket.emit("resetPoll", { type, gameId }, (res) => {
      setOpen(true);
      setText("투표 결과가 초기화되었습니다.");
      setSeverity("success");
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} disablePortal>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
          bgcolor: "#fff",
          borderRadius: "10px",
          p: "20px",
        }}
      >
        <Typography sx={{ font: "bold 24px Pretendard" }}>투표 결과 리셋하기</Typography>

        <Typography sx={{ font: "600 16px Pretendard", mt: "20px", color: "#888" }}>
          투표 결과를 리셋하시겠습니까? <br />
          리셋된 결과는 복구할 수 없습니다.
        </Typography>

        <FormControl sx={{ mt: "20px" }}>
          <RadioGroup value={type} onChange={handleType}>
            <FormControlLabel
              value="all"
              control={<Radio />}
              label={
                <span style={{ fontWeight: "600" }}>모든 슬라이드에 대한 투표 결과 리셋하기</span>
              }
            />
            <FormControlLabel
              value="current"
              control={<Radio />}
              label={
                <span style={{ fontWeight: "600" }}>현재 슬라이드에 대한 투표 결과 리셋하기</span>
              }
            />
          </RadioGroup>
        </FormControl>

        <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
          <Button
            variant="contained"
            sx={{ font: "bold 18px Pretendard", p: "10px 20px", bgcolor: "#909090" }}
            onClick={onClose}
          >
            취소
          </Button>

          <Button
            variant="contained"
            sx={{ font: "bold 18px Pretendard", p: "10px 20px", bgcolor: "#ff0000", ml: "10px" }}
            onClick={reset}
          >
            초기화
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const UserQnAModal = (props) => {
  const { open, onClose, action } = props;

  const [transform, setTransform] = useState("translate(0, 330px)");
  const [question, setQuestion] = useState("");

  const handleQuestion = (e) => {
    setQuestion(e.target.value.slice(0, 75));
  };

  useEffect(() => {
    if (open) {
      setTransform("translate(0, 0)");
    } else {
      setTransform("translate(0, 330px)");
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          transform,
          bgcolor: "#fff",
          height: "330px",
          width: "calc(100% - 15px)",
          margin: "0px 7.5px",
          borderRadius: "10px 10px 0 0",
          transition: "transform 0.3s",
          // overflow: "hidden",
          overflow: "auto",
        }}
      >
        <Box sx={{ height: "100%", position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: "7px",
              left: "50%",
              transform: "translate(-50%, 0)",
              height: "5px",
              width: "50px",
              bgcolor: "#ddd",
              borderRadius: "5px",
            }}
          />

          <IconButton sx={{ position: "absolute", top: 5, right: 5 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>

          <Box sx={{ pt: "40px", pl: "10px" }}>
            <Typography
              sx={{ font: "700 24px Pretendard", mb: "10px", fontSize: "clamp(16px, 5.4vw,24px)" }}
            >
              여기에 당신의 질문을 입력해주세요.
            </Typography>

            <Typography
              sx={{
                font: "600 16px Pretendard",
                color: "#999",
                wordBreak: "keep-all",
                mb: "30px",
                fontSize: "clamp(11px, 3.6vw, 16px)",
              }}
            >
              진행자 또는 다른 참여자가 이해하기 쉽고 명백하게 질문을 입력해주세요.
            </Typography>

            <Textfield
              fullWidth
              multiline
              maxRows={3}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ alignItems: "flex-end" }}>
                    <Typography sx={{ color: "#252b36" }}>{75 - question.length}</Typography>
                  </InputAdornment>
                ),
              }}
              value={question}
              onChange={handleQuestion}
              placeholder="질문을 입력해주세요."
            />

            <Box sx={{ mt: "30px", textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{ font: "600 18px Pretendard", p: "15px" }}
                onClick={() => action(question)}
              >
                제출하기
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

// * 라이브 보드 추가 모달
export const BoardAddModal = (props) => {
  const { open, onClose, action } = props; // * open : 모달 오픈 여부, onClose : 모달 닫기 함수, action : 보드 추가 함수

  const [title, setTitle] = useState(""); // * 보드 제목
  const [explain, setExplain] = useState(""); // * 보드 설명
  const [error, setError] = useState(false); // * 보드 제목 입력 에러 여부
  const [scale, setScale] = useState(0); // * 모달 스케일

  // * 보드 제목 입력 핸들러
  const handleTitle = (e) => {
    if (error) setError(false); // * 에러가 있을 경우 에러 해제

    setTitle(e.target.value); // * 보드 제목 변경
  };

  // * 보드 설명 입력 핸들러
  const handleExplain = (e) => {
    setExplain(e.target.value); // * 보드 설명 변경
  };

  // * 보드 추가 함수
  const addBoard = () => {
    // * 보드 제목이 없을 경우 에러 발생
    if (title === "") {
      setError(true); // * 에러 발생
      return;
    }

    // * 보드 추가 함수 실행
    action(title, explain);
  };

  // * 모달 스케일 변경
  useEffect(() => {
    // * 모달이 열릴 경우 스케일 변경
    if (open) {
      setTimeout(() => {
        setScale(1);
      }, 200);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {/* 메인 박스 */}
      <Box
        sx={{
          position: "relative",
          bgcolor: "white",
          height: "70%",
          aspectRatio: "1/1.5",
          borderRadius: "20px",
          p: "20px",
          outline: "none",
          scale,

          transition: "scale 1s",
        }}
      >
        {/* 닫기 버튼 */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* 내용 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "100%",
          }}
        >
          <Typography sx={{ font: "bold 24px Pretendard" }}>Live Board</Typography>

          {/* 이미지 박스 */}
          <Box
            sx={{
              width: "100%",
              // aspectRatio: "16/9",
              borderRadius: "10px",
              overflow: "hidden",
              // border: "2px solid #aaa",

              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <img src={boardScreenShot} alt="img" />
            {/* <Typography sx={{ font: "bold 24px Pretendard", color: "#aaa" }}>
              라이브보드 이미지
            </Typography> */}
          </Box>

          {/* 제목 입력 */}
          <Textfield
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ font: "bold 18px Pretendard" }}>제목 : </Typography>
                </InputAdornment>
              ),
            }}
            error={error}
            placeholder="보드 제목을 입력해주세요."
            helperText={
              error ? <span style={{ fontWeight: "600" }}>제목을 입력해주세요.</span> : null
            }
            value={title}
            onChange={handleTitle}
          />

          {/* 제목 입력 */}
          <Textfield
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ font: "bold 18px Pretendard" }}>설명 : </Typography>
                </InputAdornment>
              ),
            }}
            placeholder="보드 설명을 입력해주세요."
            value={explain}
            onChange={handleExplain}
          />

          <Box sx={{ flex: 1 }}></Box>

          {/* 버튼 박스 */}
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ font: "bold 18px Pretendard", bgcolor: "#aaa", "&:hover": { bgcolor: "#999" } }}
              onClick={onClose}
            >
              취소하기
            </Button>

            <Button
              fullWidth
              variant="contained"
              sx={{ font: "bold 18px Pretendard", py: "10px" }}
              onClick={addBoard}
            >
              생성하기
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export const BoardSectionDeleteModal = (props) => {
  const { open, onClose, action } = props;

  return (
    <Modal open={open} disablePortal onClose={onClose}>
      <Box
        sx={{
          bgcolor: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          outline: "none",
        }}
      >
        <Typography sx={{ font: "700 24px Pretendard" }}>이 그룹을 삭제하시겠습니까?</Typography>

        <Typography sx={{ font: "600 16px Pretendard", color: "#999" }}>
          이 그룹을 삭제하면 그룹 내 모든 게시물이 삭제되고, <br />
          복구할 수 없습니다.
        </Typography>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            sx={{ font: "600 18px Pretendard", bgcolor: "#aaa", "&:hover": { bgcolor: "#999" } }}
            fullWidth
            onClick={onClose}
          >
            취소
          </Button>

          <Button
            variant="contained"
            sx={{ font: "600 18px Pretendard", bgcolor: "#ff0000" }}
            fullWidth
            onClick={action}
          >
            삭제
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const BoardSectionSortModal = (props) => {
  const { open, onClose, sections, action } = props;

  const [sectionList, setSectionList] = useState([]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { draggableId } = result;
    const destinationIndex = result.destination.index;

    const source = sectionList.filter((section) => section.id === draggableId)[0];
    const remains = sectionList.filter((section) => section.id !== draggableId);

    const newSectionList = [
      ...remains.slice(0, destinationIndex),
      source,
      ...remains.slice(destinationIndex),
    ];

    setSectionList(newSectionList);
  };

  useEffect(() => {
    if (sections.length === 0) return;

    setSectionList(sections);
  }, [sections]);

  return (
    <Modal
      open={open}
      disablePortal
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          p: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          outline: "none",
        }}
      >
        <Typography sx={{ font: "700 24px Pretendard" }}>그룹 정렬</Typography>

        <Typography sx={{ font: "600 16px Pretendard", color: "#999" }}>
          드래그 앤 드롭으로 그룹을 정렬할 수 있습니다.
        </Typography>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="sections">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  bgcolor: "#eee",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  p: "10px",
                }}
              >
                {sectionList.map((section, index) => (
                  <Draggable key={section.id} draggableId={section.id} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          bgcolor: "#fff",
                          p: "10px",
                          m: "5px",
                          borderRadius: "8px",
                          boxShadow: "0 3px 6px #00000028",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <DragIndicatorIcon />
                        <Typography sx={{ font: "600 16px Pretendard" }}>{section.name}</Typography>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            sx={{ font: "600 18px Pretendard", bgcolor: "#aaa", "&:hover": { bgcolor: "#999" } }}
            fullWidth
            onClick={onClose}
          >
            취소
          </Button>

          <Button
            variant="contained"
            sx={{ font: "600 18px Pretendard" }}
            fullWidth
            onClick={() => action(sectionList)}
          >
            적용
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
